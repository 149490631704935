




// Import vendors ----------------------------------------------------------------------------------
import { computed, defineComponent } from '@vue/composition-api';
// -------------------------------------------------------------------------------------------------

export default defineComponent({
  name: 'CountryFlag',
  props: {
    countryCode: {
      type: String,
      required: true
    }
  },
  setup(properties) {
    const flag = computed<string>(() => {
      switch (properties.countryCode) {
        case 'en':
          return 'gb';
        default:
          return properties.countryCode;
      }
    });

    return {
      flag
    };
  }
});
