import { computed } from '@vue/composition-api';
import { useChart } from '@/utils/charts.utils';
import i18n from '@/plugins/i18n';
import { useUnit } from './unit.utils';
import { kebabCase } from 'lodash';

export enum ERehabMode {
  TripleHop = 'triple-hop',
  CMJ = 'CMJ',
  SingleHop = 'single-hop',
  SideHop = 'side-hop'
}

export function useRehab(): {
  getFormattedData: (value: number) => string | number;
  getMode: (scenarioKey: string) => ERehabMode;
  getActiveWidgets: (mode: ERehabMode) => string[];
  getSimplifiedUnit: (unit: string) => string;
  getTripleHopJumpProfileData: (aggregates: any, segments: any) => any;
  getTripleHopGeneralParametersSimplifiedData: (aggregates: any) => any;
  getTripleHopGeneralParametersAdvancedData: (aggregates: any, segments: any) => any;
  getCMJGeneralParametersSimplifiedData: (aggregates: any) => any;
  getCMJGeneralParametersAdvancedData: (aggregates: any) => any;
  getSingleHopGeneralParametersSimplifiedData: (aggregates: any) => any;
  getSingleHopGeneralParametersAdvancedData: (aggregates: any) => any;
  getSideHopGeneralParametersSimplifiedData: (aggregates: any) => any;
  getSideHopGeneralParametersAdvancedData: (aggregates: any) => any;
  getSideHopCadenceEvolutionChartOptions: (segments: any) => any;
} {
  const { isImperial } = useUnit().currentUnit();

  function getUnit<E extends { unit: string; imp_unit?: string }>(object: E) {
    return isImperial.value ? object['imp_unit'] ?? object['unit'] : object['unit'];
  }

  function getValue<E extends Record<string, number>>(object: E, key: string): number {
    return isImperial.value ? object[`imp_${key}`] ?? object[String(key)] : object[String(key)];
  }

  function getValueArray<E extends Record<string, number[]>>(object: E, key: string): number[] {
    return isImperial.value ? object[`imp_${key}`] ?? object[String(key)] : object[String(key)];
  }

  const getSimplifiedUnit = (unit: string) => {
    switch (unit) {
      case 'deg':
        return '°';
      case 'deg/s':
        return '°/s';
      case 'inch':
        return 'in';

      default:
        return unit;
    }
  };

  const getMode = (scenarioKey: string): ERehabMode => {
    if (!scenarioKey.startsWith('jumping')) throw new Error('scenario invalid');
    const mode = scenarioKey
      .replace(/^jumping_/, '')
      .replace(/_raw|_embedded$/, '')
      .replace(/_/, '-');
    if (!Object.values(ERehabMode).includes(mode as any)) throw new Error('scenario valid but incompatible');

    return mode as ERehabMode;
  };

  const getFormattedData = (value: number) => {
    if (!value && value !== 0) return '- ';
    return value;
  };

  const getTripleHopJumpProfileData = (aggregates: any, segments: any) => {
    function getJump(index: number) {
      return {
        leftSymmetry: `${getFormattedData(
          getValueArray(segments.jumping_pronation_angle_foot_in.left_foot, 'avg')[Number(index)]
        )}${getSimplifiedUnit(getUnit(aggregates.jumping_pronation_angle_foot_in))}`,
        rightSymmetry: `${getFormattedData(
          getValueArray(segments.jumping_pronation_angle_foot_in.right_foot, 'avg')[Number(index)]
        )}${getSimplifiedUnit(getUnit(aggregates.jumping_pronation_angle_foot_in))}`,
        leftDistance: `${getFormattedData(
          getValueArray(segments.jumping_stride_length.left_foot, 'avg')[Number(index)]
        )}${getSimplifiedUnit(getUnit(aggregates.jumping_stride_length))}`,
        rightDistance: `${getFormattedData(
          getValueArray(segments.jumping_stride_length.right_foot, 'avg')[Number(index)]
        )}${getSimplifiedUnit(getUnit(aggregates.jumping_stride_length))}`,
        leftTime: `${getFormattedData(
          getValueArray(segments.jumping_stance_time.left_foot, 'avg')[Number(index)]
        )}${getSimplifiedUnit(getUnit(aggregates.jumping_stance_time))}`,
        rightTime: `${getFormattedData(
          getValueArray(segments.jumping_stance_time.right_foot, 'avg')[Number(index)]
        )}${getSimplifiedUnit(getUnit(aggregates.jumping_stance_time))}`,
        advancedLeftDistance: `${getFormattedData(
          getValueArray(segments.jumping_stride_length.left_foot, 'avg')[Number(index)]
        )}${getSimplifiedUnit(getUnit(aggregates.jumping_stride_length))}`,
        advancedRightDistance: `${getFormattedData(
          getValueArray(segments.jumping_stride_length.right_foot, 'avg')[Number(index)]
        )}${getSimplifiedUnit(getUnit(aggregates.jumping_stride_length))}`,
        advancedLeftTime: `${getFormattedData(
          getValueArray(segments.jumping_flight_time.left_foot, 'avg')[Number(index)]
        )}${getSimplifiedUnit(getUnit(aggregates.jumping_flight_time))}`,
        advancedRightTime: `${getFormattedData(
          getValueArray(segments.jumping_flight_time.right_foot, 'avg')[Number(index)]
        )}${getSimplifiedUnit(getUnit(aggregates.jumping_flight_time))}`,
        advancedLeftCompareTime: `${getFormattedData(
          getValueArray(segments.jumping_stance_time.left_foot, 'avg')[Number(index)]
        )}${getSimplifiedUnit(getUnit(aggregates.jumping_stance_time))}`,
        advancedRightCompareTime: `${getFormattedData(
          getValueArray(segments.jumping_stance_time.right_foot, 'avg')[Number(index)]
        )}${getSimplifiedUnit(getUnit(aggregates.jumping_stance_time))}`,
        advancedCompareTimeDiff: `${getFormattedData(
          getValueArray(segments.jumping_stance_time.global, 'diff_R_L')[Number(index)]
        )}${getSimplifiedUnit(getUnit(aggregates.jumping_stance_time))}`,
        advancedCompareTimeDiffSide: segments.jumping_stance_time.global.side[Number(index)],
        heightDiff: `${getFormattedData(
          getValueArray(segments.jumping_stride_length.global, 'diff_R_L')[Number(index)]
        )}${getSimplifiedUnit(getUnit(aggregates.jumping_stride_length))}`,
        timeDiff: `${getFormattedData(
          getValueArray(segments.jumping_flight_time.global, 'diff_R_L')[Number(index)]
        )}${getSimplifiedUnit(getUnit(aggregates.jumping_flight_time))}`,
        heightSideDiff: segments.jumping_stride_length.global.side[Number(index)],
        timeSideDiff: segments.jumping_flight_time.global.side[Number(index)]
      };
    }

    return {
      leftContactTime: `${getFormattedData(
        getValue(aggregates.jumping_stance_time.left_foot, 'avg')
      )} ${getSimplifiedUnit(getUnit(aggregates.jumping_stance_time))}`,
      leftFlightTime: `${getFormattedData(aggregates.jumping_flight_time.left_foot.avg)} ${getSimplifiedUnit(
        getUnit(aggregates.jumping_flight_time)
      )}`,
      rightContactTime: `${getFormattedData(
        getValue(aggregates.jumping_stance_time.right_foot, 'avg')
      )} ${getSimplifiedUnit(getUnit(aggregates.jumping_stance_time))}`,
      rightFlightTime: `${getFormattedData(
        getValue(aggregates.jumping_flight_time.right_foot, 'avg')
      )} ${getSimplifiedUnit(getUnit(aggregates.jumping_flight_time))}`,
      jumpHeight: `${getFormattedData(
        getValue(aggregates.jumping_stride_length.global, 'avg_diff_R_L')
      )}${getSimplifiedUnit(getUnit(aggregates.jumping_stride_length))}`,
      totalTime: `${getFormattedData(
        getValue(aggregates.jumping_flight_time.global, 'avg_diff_R_L')
      )}${getSimplifiedUnit(getUnit(aggregates.jumping_flight_time))}`,
      jump1: getJump(0),
      jump2: getJump(1),
      jump3: getJump(2)
    };
  };

  const getTripleHopGeneralParametersSimplifiedData = (aggregates: any) => {
    return [
      {
        title: 'flexion-angle-at-take-off',
        data: [
          {
            leftData: `${getFormattedData(
              getValue(aggregates.jumping_plantar_flexion_angle_foot_out.left_foot, 'avg')
            )}${getSimplifiedUnit(getUnit(aggregates.jumping_plantar_flexion_angle_foot_out))}`,
            rightData: `${getFormattedData(
              getValue(aggregates.jumping_plantar_flexion_angle_foot_out.right_foot, 'avg')
            )}${getSimplifiedUnit(getUnit(aggregates.jumping_plantar_flexion_angle_foot_out))}`,
            diffData: `${getFormattedData(
              getValue(aggregates.jumping_plantar_flexion_angle_foot_out.global, 'avg_diff_R_L')
            )}${getSimplifiedUnit(getUnit(aggregates.jumping_plantar_flexion_angle_foot_out))}`,
            sideDiff: aggregates.jumping_plantar_flexion_angle_foot_out.global.side
          }
        ]
      },
      {
        title: 'ankle-velocity-at-take-off',
        data: [
          {
            leftData: `${getFormattedData(
              getValue(aggregates.jumping_plantar_flexion_angular_speed_foot_out.left_foot, 'avg')
            )}${getSimplifiedUnit(getUnit(aggregates.jumping_plantar_flexion_angular_speed_foot_out))}`,
            rightData: `${getFormattedData(
              getValue(aggregates.jumping_plantar_flexion_angular_speed_foot_out.right_foot, 'avg')
            )}${getSimplifiedUnit(getUnit(aggregates.jumping_plantar_flexion_angular_speed_foot_out))}`,
            diffData: `${getFormattedData(
              getValue(aggregates.jumping_plantar_flexion_angular_speed_foot_out.global, 'avg_diff_R_L')
            )}${getSimplifiedUnit(getUnit(aggregates.jumping_plantar_flexion_angular_speed_foot_out))}`,
            sideDiff: aggregates.jumping_plantar_flexion_angular_speed_foot_out.global.side
          }
        ]
      },
      {
        title: 'pronosupination-angle-landing',
        data: [
          {
            leftData: getFormattedData(getValue(aggregates.jumping_pronation_angle_foot_in.left_foot, 'avg')),
            rightData: getFormattedData(
              getValue(aggregates.jumping_pronation_angle_foot_in.right_foot, 'avg')
            ),
            diffData: getFormattedData(
              getValue(aggregates.jumping_pronation_angle_foot_in.global, 'avg_diff_R_L')
            ),
            sideDiff: getFormattedData(getValue(aggregates.jumping_pronation_angle_foot_in.global, 'side')),
            unit: getUnit(aggregates.jumping_pronation_angle_foot_in)
          }
        ]
      },
      {
        title: 'contact-stability',
        data: [
          {
            leftData: getFormattedData(
              getValue(aggregates.jumping_pronation_angle_amplitude_contact_phase.left_foot, 'avg')
            ),
            rightData: getFormattedData(
              getValue(aggregates.jumping_pronation_angle_amplitude_contact_phase.right_foot, 'avg')
            ),
            diffData: getFormattedData(
              getValue(aggregates.jumping_pronation_angle_amplitude_contact_phase.global, 'avg_diff_R_L')
            ),
            sideDiff: getFormattedData(
              getValue(aggregates.jumping_pronation_angle_amplitude_contact_phase.global, 'side')
            ),
            unit: getUnit(aggregates.jumping_pronation_angle_amplitude_contact_phase)
          },
          {
            leftData: getFormattedData(
              getValue(aggregates.jumping_pronation_angular_speed_contact_phase.left_foot, 'avg')
            ),
            rightData: getFormattedData(
              getValue(aggregates.jumping_pronation_angular_speed_contact_phase.right_foot, 'avg')
            ),
            diffData: getFormattedData(
              getValue(aggregates.jumping_pronation_angular_speed_contact_phase.global, 'avg_diff_R_L')
            ),
            sideDiff: getFormattedData(aggregates.jumping_pronation_angular_speed_contact_phase.global.side),
            unit: getUnit(aggregates.jumping_pronation_angular_speed_contact_phase)
          }
        ]
      }
    ];
  };

  const getTripleHopGeneralParametersAdvancedData = (aggregates: any, segments: any) => {
    return [
      {
        title: 'flexion-angle-at-take-off',
        data: [
          {
            jump1LeftData: getFormattedData(
              getValueArray(segments.jumping_plantar_flexion_angle_foot_out.left_foot, 'avg')[0]
            ),
            jump1DataDiff: getFormattedData(
              getValueArray(segments.jumping_plantar_flexion_angle_foot_out.global, 'diff_R_L')[0]
            ),
            jump1DataDiffSide: segments.jumping_plantar_flexion_angle_foot_out.global.side[0],
            jump2LeftData: getFormattedData(
              getValueArray(segments.jumping_plantar_flexion_angle_foot_out.left_foot, 'avg')[1]
            ),
            jump2DataDiff: getFormattedData(
              getValueArray(segments.jumping_plantar_flexion_angle_foot_out.global, 'diff_R_L')[1]
            ),
            jump2DataDiffSide: segments.jumping_plantar_flexion_angle_foot_out.global.side[1],
            jump3LeftData: getFormattedData(
              getValueArray(segments.jumping_plantar_flexion_angle_foot_out.left_foot, 'avg')[2]
            ),
            jump3DataDiff: getFormattedData(
              getValueArray(segments.jumping_plantar_flexion_angle_foot_out.global, 'diff_R_L')[2]
            ),
            jump3DataDiffSide: segments.jumping_plantar_flexion_angle_foot_out.global.side[2],
            jump1RightData: getFormattedData(
              getValueArray(segments.jumping_plantar_flexion_angle_foot_out.right_foot, 'avg')[0]
            ),
            jump2RightData: getFormattedData(
              getValueArray(segments.jumping_plantar_flexion_angle_foot_out.right_foot, 'avg')[1]
            ),
            jump3RightData: getFormattedData(
              getValueArray(segments.jumping_plantar_flexion_angle_foot_out.right_foot, 'avg')[2]
            ),
            unit: getUnit(aggregates.jumping_plantar_flexion_angle_foot_out)
          }
        ]
      },
      {
        title: 'take-off-velocity',
        data: [
          {
            jump1LeftData: getFormattedData(
              getValueArray(segments.jumping_plantar_flexion_angular_speed_foot_out.left_foot, 'avg')[0]
            ),
            jump1DataDiff: getFormattedData(
              getValueArray(segments.jumping_plantar_flexion_angular_speed_foot_out.global, 'diff_R_L')[0]
            ),
            jump1DataDiffSide: segments.jumping_plantar_flexion_angular_speed_foot_out.global.side[0],
            jump2LeftData: getFormattedData(
              getValueArray(segments.jumping_plantar_flexion_angular_speed_foot_out.left_foot, 'avg')[1]
            ),
            jump2DataDiff: getFormattedData(
              getValueArray(segments.jumping_plantar_flexion_angular_speed_foot_out.global, 'diff_R_L')[1]
            ),
            jump2DataDiffSide: segments.jumping_plantar_flexion_angular_speed_foot_out.global.side[1],
            jump3LeftData: getFormattedData(
              getValueArray(segments.jumping_plantar_flexion_angular_speed_foot_out.left_foot, 'avg')[2]
            ),
            jump3DataDiff: getFormattedData(
              getValueArray(segments.jumping_plantar_flexion_angular_speed_foot_out.global, 'diff_R_L')[2]
            ),
            jump3DataDiffSide: segments.jumping_plantar_flexion_angular_speed_foot_out.global.side[2],
            jump1RightData: getFormattedData(
              getValueArray(segments.jumping_plantar_flexion_angular_speed_foot_out.right_foot, 'avg')[0]
            ),
            jump2RightData: getFormattedData(
              getValueArray(segments.jumping_plantar_flexion_angular_speed_foot_out.right_foot, 'avg')[1]
            ),
            jump3RightData: getFormattedData(
              getValueArray(segments.jumping_plantar_flexion_angular_speed_foot_out.right_foot, 'avg')[2]
            ),
            unit: getUnit(aggregates.jumping_plantar_flexion_angular_speed_foot_out)
          }
        ]
      },
      {
        title: 'rotation-around-z',
        data: [
          {
            jump1LeftData: getFormattedData(
              getValueArray(segments.jumping_relative_heading_angle.left_foot, 'avg')[0]
            ),
            jump1DataDiff: getFormattedData(
              getValueArray(segments.jumping_relative_heading_angle.global, 'diff_R_L')[0]
            ),
            jump1DataDiffSide: segments.jumping_relative_heading_angle.global.side[0],
            jump2LeftData: getFormattedData(
              getValueArray(segments.jumping_relative_heading_angle.left_foot, 'avg')[1]
            ),
            jump2DataDiff: getFormattedData(
              getValueArray(segments.jumping_relative_heading_angle.global, 'diff_R_L')[1]
            ),
            jump2DataDiffSide: segments.jumping_relative_heading_angle.global.side[1],
            jump3LeftData: getFormattedData(
              getValueArray(segments.jumping_relative_heading_angle.left_foot, 'avg')[2]
            ),
            jump3DataDiff: getFormattedData(
              getValueArray(segments.jumping_relative_heading_angle.global, 'diff_R_L')[2]
            ),
            jump3DataDiffSide: segments.jumping_relative_heading_angle.global.side[2],
            jump1RightData: getFormattedData(
              getValueArray(segments.jumping_relative_heading_angle.right_foot, 'avg')[0]
            ),
            jump2RightData: getFormattedData(
              getValueArray(segments.jumping_relative_heading_angle.right_foot, 'avg')[1]
            ),
            jump3RightData: getFormattedData(
              getValueArray(segments.jumping_relative_heading_angle.right_foot, 'avg')[2]
            ),
            unit: getUnit(aggregates.jumping_relative_heading_angle)
          }
        ]
      },
      {
        title: 'max-acceleration-at-landing',
        data: [
          {
            jump1LeftData: getFormattedData(
              getValueArray(segments.jumping_acceleration_foot_in.left_foot, 'avg')[0]
            ),
            jump1DataDiff: getFormattedData(
              getValueArray(segments.jumping_acceleration_foot_in.global, 'diff_R_L')[0]
            ),
            jump1DataDiffSide: segments.jumping_acceleration_foot_in.global.side[0],
            jump2LeftData: getFormattedData(
              getValueArray(segments.jumping_acceleration_foot_in.left_foot, 'avg')[1]
            ),
            jump2DataDiff: getFormattedData(
              getValueArray(segments.jumping_acceleration_foot_in.global, 'diff_R_L')[1]
            ),
            jump2DataDiffSide: segments.jumping_acceleration_foot_in.global.side[1],
            jump3LeftData: getFormattedData(
              getValueArray(segments.jumping_acceleration_foot_in.left_foot, 'avg')[2]
            ),
            jump3DataDiff: getFormattedData(
              getValueArray(segments.jumping_acceleration_foot_in.global, 'diff_R_L')[2]
            ),
            jump3DataDiffSide: segments.jumping_acceleration_foot_in.global.side[2],
            jump1RightData: getFormattedData(
              getValueArray(segments.jumping_acceleration_foot_in.right_foot, 'avg')[0]
            ),
            jump2RightData: getFormattedData(
              getValueArray(segments.jumping_acceleration_foot_in.right_foot, 'avg')[1]
            ),
            jump3RightData: getFormattedData(
              getValueArray(segments.jumping_acceleration_foot_in.right_foot, 'avg')[2]
            ),
            unit: getUnit(aggregates.jumping_acceleration_foot_in)
          }
        ]
      },
      {
        title: 'flexion-angle-at-landing',
        data: [
          {
            jump1LeftData: getFormattedData(
              getValueArray(segments.jumping_plantar_flexion_angle_foot_in.left_foot, 'avg')[0]
            ),
            jump1DataDiff: getFormattedData(
              getValueArray(segments.jumping_plantar_flexion_angle_foot_in.global, 'diff_R_L')[0]
            ),
            jump1DataDiffSide: segments.jumping_plantar_flexion_angle_foot_in.global.side[0],
            jump2LeftData: getFormattedData(
              getValueArray(segments.jumping_plantar_flexion_angle_foot_in.left_foot, 'avg')[1]
            ),
            jump2DataDiff: getFormattedData(
              getValueArray(segments.jumping_plantar_flexion_angle_foot_in.global, 'diff_R_L')[1]
            ),
            jump2DataDiffSide: segments.jumping_plantar_flexion_angle_foot_in.global.side[1],
            jump3LeftData: getFormattedData(
              getValueArray(segments.jumping_plantar_flexion_angle_foot_in.left_foot, 'avg')[2]
            ),
            jump3DataDiff: getFormattedData(
              getValueArray(segments.jumping_plantar_flexion_angle_foot_in.global, 'diff_R_L')[2]
            ),
            jump3DataDiffSide: segments.jumping_plantar_flexion_angle_foot_in.global.side[2],
            jump1RightData: getFormattedData(
              getValueArray(segments.jumping_plantar_flexion_angle_foot_in.right_foot, 'avg')[0]
            ),
            jump2RightData: getFormattedData(
              getValueArray(segments.jumping_plantar_flexion_angle_foot_in.right_foot, 'avg')[1]
            ),
            jump3RightData: getFormattedData(
              getValueArray(segments.jumping_plantar_flexion_angle_foot_in.right_foot, 'avg')[2]
            ),
            unit: getUnit(aggregates.jumping_plantar_flexion_angle_foot_in)
          }
        ]
      },
      {
        title: 'pronosupination-angle-landing',
        data: [
          {
            jump1LeftData: getFormattedData(
              getValueArray(segments.jumping_pronation_angle_foot_in.left_foot, 'avg')[0]
            ),
            jump1DataDiff: getFormattedData(
              getValueArray(segments.jumping_pronation_angle_foot_in.global, 'diff_R_L')[0]
            ),
            jump1DataDiffSide: segments.jumping_pronation_angle_foot_in.global.side[0],
            jump2LeftData: getFormattedData(
              getValueArray(segments.jumping_pronation_angle_foot_in.left_foot, 'avg')[1]
            ),
            jump2DataDiff: getFormattedData(
              getValueArray(segments.jumping_pronation_angle_foot_in.global, 'diff_R_L')[1]
            ),
            jump2DataDiffSide: segments.jumping_pronation_angle_foot_in.global.side[1],
            jump3LeftData: getFormattedData(
              getValueArray(segments.jumping_pronation_angle_foot_in.left_foot, 'avg')[2]
            ),
            jump3DataDiff: getFormattedData(
              getValueArray(segments.jumping_pronation_angle_foot_in.global, 'diff_R_L')[2]
            ),
            jump3DataDiffSide: segments.jumping_pronation_angle_foot_in.global.side[2],
            jump1RightData: getFormattedData(
              getValueArray(segments.jumping_pronation_angle_foot_in.right_foot, 'avg')[0]
            ),
            jump2RightData: getFormattedData(
              getValueArray(segments.jumping_pronation_angle_foot_in.right_foot, 'avg')[1]
            ),
            jump3RightData: getFormattedData(
              getValueArray(segments.jumping_pronation_angle_foot_in.right_foot, 'avg')[2]
            ),
            unit: getUnit(aggregates.jumping_pronation_angle_foot_in)
          }
        ]
      },
      {
        title: 'contact-stability',
        data: [
          {
            jump1LeftData: getFormattedData(
              getValueArray(segments.jumping_pronation_angle_amplitude_contact_phase.left_foot, 'avg')[0]
            ),
            jump1DataDiff: getFormattedData(
              getValueArray(segments.jumping_pronation_angle_amplitude_contact_phase.global, 'diff_R_L')[0]
            ),
            jump1DataDiffSide: segments.jumping_pronation_angle_amplitude_contact_phase.global.side[0],
            jump2LeftData: getFormattedData(
              getValueArray(segments.jumping_pronation_angle_amplitude_contact_phase.left_foot, 'avg')[1]
            ),
            jump2DataDiff: getFormattedData(
              getValueArray(segments.jumping_pronation_angle_amplitude_contact_phase.global, 'diff_R_L')[1]
            ),
            jump2DataDiffSide: segments.jumping_pronation_angle_amplitude_contact_phase.global.side[1],
            jump3LeftData: getFormattedData(
              getValueArray(segments.jumping_pronation_angle_amplitude_contact_phase.left_foot, 'avg')[2]
            ),
            jump3DataDiff: getFormattedData(
              getValueArray(segments.jumping_pronation_angle_amplitude_contact_phase.global, 'diff_R_L')[2]
            ),
            jump3DataDiffSide: segments.jumping_pronation_angle_amplitude_contact_phase.global.side[2],
            jump1RightData: getFormattedData(
              getValueArray(segments.jumping_pronation_angle_amplitude_contact_phase.right_foot, 'avg')[0]
            ),
            jump2RightData: getFormattedData(
              getValueArray(segments.jumping_pronation_angle_amplitude_contact_phase.right_foot, 'avg')[1]
            ),
            jump3RightData: getFormattedData(
              getValueArray(segments.jumping_pronation_angle_amplitude_contact_phase.right_foot, 'avg')[2]
            ),
            unit: getUnit(aggregates.jumping_pronation_angle_amplitude_contact_phase)
          },
          {
            jump1LeftData: getFormattedData(
              getValueArray(segments.jumping_pronation_angular_speed_contact_phase.left_foot, 'avg')[0]
            ),
            jump1DataDiff: getFormattedData(
              getValueArray(segments.jumping_pronation_angular_speed_contact_phase.global, 'diff_R_L')[0]
            ),
            jump1DataDiffSide: segments.jumping_pronation_angular_speed_contact_phase.global.side[0],
            jump2LeftData: getFormattedData(
              getValueArray(segments.jumping_pronation_angular_speed_contact_phase.left_foot, 'avg')[1]
            ),
            jump2DataDiff: getFormattedData(
              getValueArray(segments.jumping_pronation_angular_speed_contact_phase.global, 'diff_R_L')[1]
            ),
            jump2DataDiffSide: segments.jumping_pronation_angular_speed_contact_phase.global.side[1],
            jump3LeftData: getFormattedData(
              getValueArray(segments.jumping_pronation_angular_speed_contact_phase.left_foot, 'avg')[2]
            ),
            jump3DataDiff: getFormattedData(
              getValueArray(segments.jumping_pronation_angular_speed_contact_phase.global, 'diff_R_L')[2]
            ),
            jump3DataDiffSide: segments.jumping_pronation_angular_speed_contact_phase.global.side[2],
            jump1RightData: getFormattedData(
              getValueArray(segments.jumping_pronation_angular_speed_contact_phase.right_foot, 'avg')[0]
            ),
            jump2RightData: getFormattedData(
              getValueArray(segments.jumping_pronation_angular_speed_contact_phase.right_foot, 'avg')[1]
            ),
            jump3RightData: getFormattedData(
              getValueArray(segments.jumping_pronation_angular_speed_contact_phase.right_foot, 'avg')[2]
            ),
            unit: getUnit(aggregates.jumping_pronation_angular_speed_contact_phase)
          }
        ]
      }
      /*{
        title: 'take-off-velocity',
        data: [
          {
            jump1LeftData: getFormattedData(
              getValueArray(segments.jumping_plantar_flexion_angular_speed_foot_out.left_foot,'avg')[0]
            ),
            jump1DataDiff: getFormattedData(
              getValueArray(segments.jumping_plantar_flexion_angular_speed_foot_out.global,'diff_R_L')[0]
            ),
            jump1DataDiffSide: getValueArray(segments.jumping_plantar_flexion_angular_speed_foot_out.global,'side')[0],
            jump2LeftData: getFormattedData(
              getValueArray(segments.jumping_plantar_flexion_angular_speed_foot_out.left_foot,'avg')[1]
            ),
            jump2DataDiff: getFormattedData(
              getValueArray(segments.jumping_plantar_flexion_angular_speed_foot_out.global,'diff_R_L')[1]
            ),
            jump2DataDiffSide: getValueArray(segments.jumping_plantar_flexion_angular_speed_foot_out.global,'side')[1],
            jump3LeftData: getFormattedData(
              getValueArray(segments.jumping_plantar_flexion_angular_speed_foot_out.left_foot,'avg')[2]
            ),
            jump3DataDiff: getFormattedData(
              getValueArray(segments.jumping_plantar_flexion_angular_speed_foot_out.global,'diff_R_L')[2]
            ),
            jump3DataDiffSide: getValueArray(segments.jumping_plantar_flexion_angular_speed_foot_out.global,'side')[2],
            jump1RightData: getFormattedData(
              getValueArray(segments.jumping_plantar_flexion_angular_speed_foot_out.right_foot,'avg')[0]
            ),
            jump2RightData: getFormattedData(
              getValueArray(segments.jumping_plantar_flexion_angular_speed_foot_out.right_foot,'avg')[1]
            ),
            jump3RightData: getFormattedData(
              getValueArray(segments.jumping_plantar_flexion_angular_speed_foot_out.right_foot,'avg')[2]
            ),
            unit: getUnit(aggregates.jumping_plantar_flexion_angular_speed_foot_out)
          }
        ]
      }*/
    ];
  };

  const getCMJGeneralParametersSimplifiedData = (aggregates: any) => {
    return [
      {
        title: 'flexion-angle-at-take-off',
        data: [
          {
            leftData: `${getFormattedData(
              getValue(aggregates.jumping_plantar_flexion_angle_foot_out.left_foot, 'avg')
            )}${getSimplifiedUnit(getUnit(aggregates.jumping_plantar_flexion_angle_foot_out))}`,
            rightData: `${getFormattedData(
              getValue(aggregates.jumping_plantar_flexion_angle_foot_out.right_foot, 'avg')
            )}${getSimplifiedUnit(getUnit(aggregates.jumping_plantar_flexion_angle_foot_out))}`,
            diffData: `${getFormattedData(
              getValue(aggregates.jumping_plantar_flexion_angle_foot_out.global, 'avg_diff_R_L')
            )}${getSimplifiedUnit(getUnit(aggregates.jumping_plantar_flexion_angle_foot_out))}`,
            sideDiff: aggregates.jumping_plantar_flexion_angle_foot_out.global.side
          }
        ]
      },
      {
        title: 'take-off-velocity',
        data: [
          {
            leftData: `${getFormattedData(
              getValue(aggregates.jumping_plantar_flexion_angular_speed_foot_out.left_foot, 'avg')
            )}${getSimplifiedUnit(getUnit(aggregates.jumping_plantar_flexion_angular_speed_foot_out))}`,
            rightData: `${getFormattedData(
              getValue(aggregates.jumping_plantar_flexion_angular_speed_foot_out.right_foot, 'avg')
            )}${getSimplifiedUnit(getUnit(aggregates.jumping_plantar_flexion_angular_speed_foot_out))}`,
            diffData: `${getFormattedData(
              getValue(aggregates.jumping_plantar_flexion_angular_speed_foot_out.global, 'avg_diff_R_L')
            )}${getSimplifiedUnit(getUnit(aggregates.jumping_plantar_flexion_angular_speed_foot_out))}`,
            sideDiff: aggregates.jumping_plantar_flexion_angular_speed_foot_out.global.side
          }
        ]
      },
      {
        title: 'contact-stability',
        data: [
          {
            leftData: `${getFormattedData(
              getValue(aggregates.jumping_pronation_angle_amplitude_contact_phase.left_foot, 'avg')
            )}${getSimplifiedUnit(getUnit(aggregates.jumping_pronation_angle_amplitude_contact_phase))}`,
            rightData: `${getFormattedData(
              getValue(aggregates.jumping_pronation_angle_amplitude_contact_phase.right_foot, 'avg')
            )}${getSimplifiedUnit(getUnit(aggregates.jumping_pronation_angle_amplitude_contact_phase))}`,
            diffData: `${getFormattedData(
              getValue(aggregates.jumping_pronation_angle_amplitude_contact_phase.global, 'avg_diff_R_L')
            )}${getSimplifiedUnit(getUnit(aggregates.jumping_pronation_angle_amplitude_contact_phase))}`,
            sideDiff: aggregates.jumping_pronation_angle_amplitude_contact_phase.global.side
          },
          {
            leftData: `${getFormattedData(
              getValue(aggregates.jumping_pronation_angular_speed_contact_phase.left_foot, 'avg')
            )}${getSimplifiedUnit(getUnit(aggregates.jumping_pronation_angular_speed_contact_phase))}`,
            rightData: `${getFormattedData(
              getValue(aggregates.jumping_pronation_angular_speed_contact_phase.right_foot, 'avg')
            )}${getSimplifiedUnit(getUnit(aggregates.jumping_pronation_angular_speed_contact_phase))}`,
            diffData: `${getFormattedData(
              getValue(aggregates.jumping_pronation_angular_speed_contact_phase.global, 'avg_diff_R_L')
            )}${getSimplifiedUnit(getUnit(aggregates.jumping_pronation_angular_speed_contact_phase))}`,
            sideDiff: aggregates.jumping_pronation_angular_speed_contact_phase.global.side
          }
        ]
      }
    ];
  };

  const getCMJGeneralParametersAdvancedData = (aggregates: any) => {
    return [
      {
        title: 'flexion-angle-at-take-off',
        data: [
          {
            leftData: `${getFormattedData(
              getValue(aggregates.jumping_plantar_flexion_angle_foot_out.left_foot, 'avg')
            )}${getSimplifiedUnit(getUnit(aggregates.jumping_plantar_flexion_angle_foot_out))}`,
            rightData: `${getFormattedData(
              getValue(aggregates.jumping_plantar_flexion_angle_foot_out.right_foot, 'avg')
            )}${getSimplifiedUnit(getUnit(aggregates.jumping_plantar_flexion_angle_foot_out))}`,
            diffData: `${getFormattedData(
              getValue(aggregates.jumping_plantar_flexion_angle_foot_out.global, 'avg_diff_R_L')
            )}${getSimplifiedUnit(getUnit(aggregates.jumping_plantar_flexion_angle_foot_out))}`,
            sideDiff: aggregates.jumping_plantar_flexion_angle_foot_out.global.side
          }
        ]
      },
      {
        title: 'take-off-velocity',
        data: [
          {
            leftData: `${getFormattedData(
              getValue(aggregates.jumping_plantar_flexion_angular_speed_foot_out.left_foot, 'avg')
            )}${getSimplifiedUnit(getUnit(aggregates.jumping_plantar_flexion_angular_speed_foot_out))}`,
            rightData: `${getFormattedData(
              getValue(aggregates.jumping_plantar_flexion_angular_speed_foot_out.right_foot, 'avg')
            )}${getSimplifiedUnit(getUnit(aggregates.jumping_plantar_flexion_angular_speed_foot_out))}`,
            diffData: `${getFormattedData(
              getValue(aggregates.jumping_plantar_flexion_angular_speed_foot_out.global, 'avg_diff_R_L')
            )}${getSimplifiedUnit(getUnit(aggregates.jumping_plantar_flexion_angular_speed_foot_out))}`,
            sideDiff: aggregates.jumping_plantar_flexion_angular_speed_foot_out.global.side
          }
        ]
      },
      {
        title: 'rotation-around-z',
        data: [
          {
            leftData: `${getFormattedData(
              getValue(aggregates.jumping_relative_heading_angle.left_foot, 'avg')
            )}${getSimplifiedUnit(getUnit(aggregates.jumping_relative_heading_angle))}`,
            rightData: `${getFormattedData(
              getValue(aggregates.jumping_relative_heading_angle.right_foot, 'avg')
            )}${getSimplifiedUnit(getUnit(aggregates.jumping_relative_heading_angle))}`,
            diffData: `${getFormattedData(
              getValue(aggregates.jumping_relative_heading_angle.global, 'avg_diff_R_L')
            )}${getSimplifiedUnit(getUnit(aggregates.jumping_relative_heading_angle))}`,
            sideDiff: aggregates.jumping_relative_heading_angle.global.side
          }
        ]
      },
      {
        title: 'max-acceleration-at-landing',
        data: [
          {
            leftData: `${getFormattedData(
              getValue(aggregates.jumping_acceleration_foot_in.left_foot, 'avg')
            )}${getSimplifiedUnit(getUnit(aggregates.jumping_acceleration_foot_in))}`,
            rightData: `${getFormattedData(
              getValue(aggregates.jumping_acceleration_foot_in.right_foot, 'avg')
            )}${getSimplifiedUnit(getUnit(aggregates.jumping_acceleration_foot_in))}`,
            diffData: `${getFormattedData(
              getValue(aggregates.jumping_acceleration_foot_in.global, 'avg_diff_R_L')
            )}${getSimplifiedUnit(getUnit(aggregates.jumping_acceleration_foot_in))}`,
            sideDiff: aggregates.jumping_acceleration_foot_in.global.side
          }
        ]
      },
      {
        title: 'flexion-angle-at-landing',
        data: [
          {
            leftData: `${getFormattedData(
              getValue(aggregates.jumping_plantar_flexion_angle_foot_in.left_foot, 'avg')
            )}${getSimplifiedUnit(getUnit(aggregates.jumping_plantar_flexion_angle_foot_in))}`,
            rightData: `${getFormattedData(
              getValue(aggregates.jumping_plantar_flexion_angle_foot_in.right_foot, 'avg')
            )}${getSimplifiedUnit(getUnit(aggregates.jumping_plantar_flexion_angle_foot_in))}`,
            diffData: `${getFormattedData(
              getValue(aggregates.jumping_plantar_flexion_angle_foot_in.global, 'avg_diff_R_L')
            )}${getSimplifiedUnit(getUnit(aggregates.jumping_plantar_flexion_angle_foot_in))}`,
            sideDiff: aggregates.jumping_plantar_flexion_angle_foot_in.global.side
          }
        ]
      },
      {
        title: 'pronosupination-angle-landing',
        data: [
          {
            leftData: `${getFormattedData(
              getValue(aggregates.jumping_pronation_angle_foot_in.left_foot, 'avg')
            )}${getSimplifiedUnit(getUnit(aggregates.jumping_pronation_angle_foot_in))}`,
            rightData: `${getFormattedData(
              getValue(aggregates.jumping_pronation_angle_foot_in.right_foot, 'avg')
            )}${getSimplifiedUnit(getUnit(aggregates.jumping_pronation_angle_foot_in))}`,
            diffData: `${getFormattedData(
              getValue(aggregates.jumping_pronation_angle_foot_in.global, 'avg_diff_R_L')
            )}${getSimplifiedUnit(getUnit(aggregates.jumping_pronation_angle_foot_in))}`,
            sideDiff: aggregates.jumping_pronation_angle_foot_in.global.side
          }
        ]
      },
      {
        title: 'contact-stability',
        data: [
          {
            leftData: `${getFormattedData(
              getValue(aggregates.jumping_pronation_angle_amplitude_contact_phase.left_foot, 'avg')
            )}${getSimplifiedUnit(getUnit(aggregates.jumping_pronation_angle_amplitude_contact_phase))}`,
            rightData: `${getFormattedData(
              getValue(aggregates.jumping_pronation_angle_amplitude_contact_phase.right_foot, 'avg')
            )}${getSimplifiedUnit(getUnit(aggregates.jumping_pronation_angle_amplitude_contact_phase))}`,
            diffData: `${getFormattedData(
              getValue(aggregates.jumping_pronation_angle_amplitude_contact_phase.global, 'avg_diff_R_L')
            )}${getSimplifiedUnit(getUnit(aggregates.jumping_pronation_angle_amplitude_contact_phase))}`,
            sideDiff: aggregates.jumping_pronation_angle_amplitude_contact_phase.global.side
          },
          {
            leftData: `${getFormattedData(
              getValue(aggregates.jumping_pronation_angular_speed_contact_phase.left_foot, 'avg')
            )}${getSimplifiedUnit(getUnit(aggregates.jumping_pronation_angular_speed_contact_phase))}`,
            rightData: `${getFormattedData(
              getValue(aggregates.jumping_pronation_angular_speed_contact_phase.right_foot, 'avg')
            )}${getSimplifiedUnit(getUnit(aggregates.jumping_pronation_angular_speed_contact_phase))}`,
            diffData: `${getFormattedData(
              getValue(aggregates.jumping_pronation_angular_speed_contact_phase.global, 'avg_diff_R_L')
            )}${getSimplifiedUnit(getUnit(aggregates.jumping_pronation_angular_speed_contact_phase))}`,
            sideDiff: aggregates.jumping_pronation_angular_speed_contact_phase.global.side
          }
        ]
      }
    ];
  };

  const getSingleHopGeneralParametersSimplifiedData = (aggregates: any) => {
    return [
      {
        title: 'flexion-angle-at-take-off',
        data: [
          {
            leftData: `${getFormattedData(
              getValue(aggregates.jumping_plantar_flexion_angle_foot_out.left_foot, 'avg')
            )}${getSimplifiedUnit(getUnit(aggregates.jumping_plantar_flexion_angle_foot_out))}`,
            rightData: `${getFormattedData(
              getValue(aggregates.jumping_plantar_flexion_angle_foot_out.right_foot, 'avg')
            )}${getSimplifiedUnit(getUnit(aggregates.jumping_plantar_flexion_angle_foot_out))}`,
            diffData: `${getFormattedData(
              getValue(aggregates.jumping_plantar_flexion_angle_foot_out.global, 'avg_diff_R_L')
            )}${getSimplifiedUnit(getUnit(aggregates.jumping_plantar_flexion_angle_foot_out))}`,
            sideDiff: aggregates.jumping_plantar_flexion_angle_foot_out.global.side
          }
        ]
      },
      {
        title: 'take-off-velocity',
        data: [
          {
            leftData: `${getFormattedData(
              getValue(aggregates.jumping_plantar_flexion_angular_speed_foot_out.left_foot, 'avg')
            )}${getSimplifiedUnit(getUnit(aggregates.jumping_plantar_flexion_angular_speed_foot_out))}`,
            rightData: `${getFormattedData(
              getValue(aggregates.jumping_plantar_flexion_angular_speed_foot_out.right_foot, 'avg')
            )}${getSimplifiedUnit(getUnit(aggregates.jumping_plantar_flexion_angular_speed_foot_out))}`,
            diffData: `${getFormattedData(
              getValue(aggregates.jumping_plantar_flexion_angular_speed_foot_out.global, 'avg_diff_R_L')
            )}${getSimplifiedUnit(getUnit(aggregates.jumping_plantar_flexion_angular_speed_foot_out))}`,
            sideDiff: aggregates.jumping_plantar_flexion_angular_speed_foot_out.global.side
          }
        ]
      },
      {
        title: 'pronosupination-angle-landing',
        data: [
          {
            leftData: `${getFormattedData(
              getValue(aggregates.jumping_pronation_angle_foot_in.left_foot, 'avg')
            )}${getSimplifiedUnit(getUnit(aggregates.jumping_pronation_angle_foot_in))}`,
            rightData: `${getFormattedData(
              getValue(aggregates.jumping_pronation_angle_foot_in.right_foot, 'avg')
            )}${getSimplifiedUnit(getUnit(aggregates.jumping_pronation_angle_foot_in))}`,
            diffData: `${getFormattedData(
              getValue(aggregates.jumping_pronation_angle_foot_in.global, 'avg_diff_R_L')
            )}${getSimplifiedUnit(getUnit(aggregates.jumping_pronation_angle_foot_in))}`,
            sideDiff: aggregates.jumping_pronation_angle_foot_in.global.side
          }
        ]
      },
      {
        title: 'contact-stability',
        data: [
          {
            leftData: `${getFormattedData(
              getValue(aggregates.jumping_pronation_angle_amplitude_contact_phase.left_foot, 'avg')
            )}${getSimplifiedUnit(getUnit(aggregates.jumping_pronation_angle_amplitude_contact_phase))}`,
            rightData: `${getFormattedData(
              getValue(aggregates.jumping_pronation_angle_amplitude_contact_phase.right_foot, 'avg')
            )}${getSimplifiedUnit(getUnit(aggregates.jumping_pronation_angle_amplitude_contact_phase))}`,
            diffData: `${getFormattedData(
              getValue(aggregates.jumping_pronation_angle_amplitude_contact_phase.global, 'avg_diff_R_L')
            )}${getSimplifiedUnit(getUnit(aggregates.jumping_pronation_angle_amplitude_contact_phase))}`,
            sideDiff: aggregates.jumping_pronation_angle_amplitude_contact_phase.global.side
          },
          {
            leftData: `${getFormattedData(
              getValue(aggregates.jumping_pronation_angular_speed_contact_phase.left_foot, 'avg')
            )}${getSimplifiedUnit(getUnit(aggregates.jumping_pronation_angular_speed_contact_phase))}`,
            rightData: `${getFormattedData(
              getValue(aggregates.jumping_pronation_angular_speed_contact_phase.right_foot, 'avg')
            )}${getSimplifiedUnit(getUnit(aggregates.jumping_pronation_angular_speed_contact_phase))}`,
            diffData: `${getFormattedData(
              getValue(aggregates.jumping_pronation_angular_speed_contact_phase.global, 'avg_diff_R_L')
            )}${getSimplifiedUnit(getUnit(aggregates.jumping_pronation_angular_speed_contact_phase))}`,
            sideDiff: aggregates.jumping_pronation_angular_speed_contact_phase.global.side
          }
        ]
      }
    ];
  };

  const getSingleHopGeneralParametersAdvancedData = (aggregates: any) => {
    return [
      {
        title: 'flexion-angle-at-take-off',
        data: [
          {
            leftData: `${getFormattedData(
              getValue(aggregates.jumping_plantar_flexion_angle_foot_out.left_foot, 'avg')
            )}${getSimplifiedUnit(getUnit(aggregates.jumping_plantar_flexion_angle_foot_out))}`,
            rightData: `${getFormattedData(
              getValue(aggregates.jumping_plantar_flexion_angle_foot_out.right_foot, 'avg')
            )}${getSimplifiedUnit(getUnit(aggregates.jumping_plantar_flexion_angle_foot_out))}`,
            diffData: `${getFormattedData(
              getValue(aggregates.jumping_plantar_flexion_angle_foot_out.global, 'avg_diff_R_L')
            )}${getSimplifiedUnit(getUnit(aggregates.jumping_plantar_flexion_angle_foot_out))}`,
            sideDiff: aggregates.jumping_plantar_flexion_angle_foot_out.global.side
          }
        ]
      },
      {
        title: 'take-off-velocity',
        data: [
          {
            leftData: `${getFormattedData(
              getValue(aggregates.jumping_plantar_flexion_angular_speed_foot_out.left_foot, 'avg')
            )}${getSimplifiedUnit(getUnit(aggregates.jumping_plantar_flexion_angular_speed_foot_out))}`,
            rightData: `${getFormattedData(
              getValue(aggregates.jumping_plantar_flexion_angular_speed_foot_out.right_foot, 'avg')
            )}${getSimplifiedUnit(getUnit(aggregates.jumping_plantar_flexion_angular_speed_foot_out))}`,
            diffData: `${getFormattedData(
              getValue(aggregates.jumping_plantar_flexion_angular_speed_foot_out.global, 'avg_diff_R_L')
            )}${getSimplifiedUnit(getUnit(aggregates.jumping_plantar_flexion_angular_speed_foot_out))}`,
            sideDiff: aggregates.jumping_plantar_flexion_angular_speed_foot_out.global.side
          }
        ]
      },
      {
        title: 'rotation-around-z',
        data: [
          {
            leftData: `${getFormattedData(
              getValue(aggregates.jumping_relative_heading_angle.left_foot, 'avg')
            )}${getSimplifiedUnit(getUnit(aggregates.jumping_relative_heading_angle))}`,
            rightData: `${getFormattedData(
              getValue(aggregates.jumping_relative_heading_angle.right_foot, 'avg')
            )}${getSimplifiedUnit(getUnit(aggregates.jumping_relative_heading_angle))}`,
            diffData: `${getFormattedData(
              getValue(aggregates.jumping_relative_heading_angle.global, 'avg_diff_R_L')
            )}${getSimplifiedUnit(getUnit(aggregates.jumping_relative_heading_angle))}`,
            sideDiff: aggregates.jumping_relative_heading_angle.global.side
          }
        ]
      },
      {
        title: 'max-acceleration-at-landing',
        data: [
          {
            leftData: `${getFormattedData(
              getValue(aggregates.jumping_acceleration_foot_in.left_foot, 'avg')
            )}${getSimplifiedUnit(getUnit(aggregates.jumping_acceleration_foot_in))}`,
            rightData: `${getFormattedData(
              getValue(aggregates.jumping_acceleration_foot_in.right_foot, 'avg')
            )}${getSimplifiedUnit(getUnit(aggregates.jumping_acceleration_foot_in))}`,
            diffData: `${getFormattedData(
              getValue(aggregates.jumping_acceleration_foot_in.global, 'avg_diff_R_L')
            )}${getSimplifiedUnit(getUnit(aggregates.jumping_acceleration_foot_in))}`,
            sideDiff: aggregates.jumping_acceleration_foot_in.global.side
          }
        ]
      },
      {
        title: 'flexion-angle-at-landing',
        data: [
          {
            leftData: `${getFormattedData(
              getValue(aggregates.jumping_plantar_flexion_angle_foot_in.left_foot, 'avg')
            )}${getSimplifiedUnit(getUnit(aggregates.jumping_plantar_flexion_angle_foot_in))}`,
            rightData: `${getFormattedData(
              getValue(aggregates.jumping_plantar_flexion_angle_foot_in.right_foot, 'avg')
            )}${getSimplifiedUnit(getUnit(aggregates.jumping_plantar_flexion_angle_foot_in))}`,
            diffData: `${getFormattedData(
              getValue(aggregates.jumping_plantar_flexion_angle_foot_in.global, 'avg_diff_R_L')
            )}${getSimplifiedUnit(getUnit(aggregates.jumping_plantar_flexion_angle_foot_in))}`,
            sideDiff: aggregates.jumping_plantar_flexion_angle_foot_in.global.side
          }
        ]
      },
      {
        title: 'pronosupination-angle-landing',
        data: [
          {
            leftData: `${getFormattedData(
              getValue(aggregates.jumping_pronation_angle_foot_in.left_foot, 'avg')
            )}${getSimplifiedUnit(getUnit(aggregates.jumping_pronation_angle_foot_in))}`,
            rightData: `${getFormattedData(
              getValue(aggregates.jumping_pronation_angle_foot_in.right_foot, 'avg')
            )}${getSimplifiedUnit(getUnit(aggregates.jumping_pronation_angle_foot_in))}`,
            diffData: `${getFormattedData(
              getValue(aggregates.jumping_pronation_angle_foot_in.global, 'avg_diff_R_L')
            )}${getSimplifiedUnit(getUnit(aggregates.jumping_pronation_angle_foot_in))}`,
            sideDiff: aggregates.jumping_pronation_angle_foot_in.global.side
          }
        ]
      },
      {
        title: 'contact-stability',
        data: [
          {
            leftData: `${getFormattedData(
              getValue(aggregates.jumping_pronation_angle_amplitude_contact_phase.left_foot, 'avg')
            )}${getSimplifiedUnit(getUnit(aggregates.jumping_pronation_angle_amplitude_contact_phase))}`,
            rightData: `${getFormattedData(
              getValue(aggregates.jumping_pronation_angle_amplitude_contact_phase.right_foot, 'avg')
            )}${getSimplifiedUnit(getUnit(aggregates.jumping_pronation_angle_amplitude_contact_phase))}`,
            diffData: `${getFormattedData(
              getValue(aggregates.jumping_pronation_angle_amplitude_contact_phase.global, 'avg_diff_R_L')
            )}${getSimplifiedUnit(getUnit(aggregates.jumping_pronation_angle_amplitude_contact_phase))}`,
            sideDiff: aggregates.jumping_pronation_angle_amplitude_contact_phase.global.side
          },
          {
            leftData: `${getFormattedData(
              getValue(aggregates.jumping_pronation_angular_speed_contact_phase.left_foot, 'avg')
            )}${getSimplifiedUnit(getUnit(aggregates.jumping_pronation_angular_speed_contact_phase))}`,
            rightData: `${getFormattedData(
              getValue(aggregates.jumping_pronation_angular_speed_contact_phase.right_foot, 'avg')
            )}${getSimplifiedUnit(getUnit(aggregates.jumping_pronation_angular_speed_contact_phase))}`,
            diffData: `${getFormattedData(
              getValue(aggregates.jumping_pronation_angular_speed_contact_phase.global, 'avg_diff_R_L')
            )}${getSimplifiedUnit(getUnit(aggregates.jumping_pronation_angular_speed_contact_phase))}`,
            sideDiff: aggregates.jumping_pronation_angular_speed_contact_phase.global.side
          }
        ]
      }
    ];
  };

  const getSideHopGeneralParametersSimplifiedData = (aggregates: any) => {
    return [
      {
        title: 'flexion-angle-at-take-off',
        data: [
          {
            leftData: `${getFormattedData(
              getValue(aggregates.jumping_plantar_flexion_angle_foot_out.left_foot, 'avg')
            )}${getSimplifiedUnit(getUnit(aggregates.jumping_plantar_flexion_angle_foot_out))}`,
            rightData: `${getFormattedData(
              getValue(aggregates.jumping_plantar_flexion_angle_foot_out.right_foot, 'avg')
            )}${getSimplifiedUnit(getUnit(aggregates.jumping_plantar_flexion_angle_foot_out))}`,
            diffData: `${getFormattedData(
              getValue(aggregates.jumping_plantar_flexion_angle_foot_out.global, 'avg_diff_R_L')
            )}${getSimplifiedUnit(getUnit(aggregates.jumping_plantar_flexion_angle_foot_out))}`,
            sideDiff: aggregates.jumping_plantar_flexion_angle_foot_out.global.side
          }
        ]
      },
      {
        title: 'ankle-velocity-at-take-off',
        data: [
          {
            leftData: `${getFormattedData(
              getValue(aggregates.jumping_plantar_flexion_angular_speed_foot_out.left_foot, 'avg')
            )}${getSimplifiedUnit(getUnit(aggregates.jumping_plantar_flexion_angular_speed_foot_out))}`,
            rightData: `${getFormattedData(
              getValue(aggregates.jumping_plantar_flexion_angular_speed_foot_out.right_foot, 'avg')
            )}${getSimplifiedUnit(getUnit(aggregates.jumping_plantar_flexion_angular_speed_foot_out))}`,
            diffData: `${getFormattedData(
              getValue(aggregates.jumping_plantar_flexion_angular_speed_foot_out.global, 'avg_diff_R_L')
            )}${getSimplifiedUnit(getUnit(aggregates.jumping_plantar_flexion_angular_speed_foot_out))}`,
            sideDiff: aggregates.jumping_plantar_flexion_angular_speed_foot_out.global.side
          }
        ]
      },
      {
        title: 'contact-stability',
        data: [
          {
            leftData: `${getFormattedData(
              getValue(aggregates.jumping_pronation_angle_amplitude_contact_phase.left_foot, 'avg')
            )}${getSimplifiedUnit(getUnit(aggregates.jumping_pronation_angle_amplitude_contact_phase))}`,
            rightData: `${getFormattedData(
              getValue(aggregates.jumping_pronation_angle_amplitude_contact_phase.right_foot, 'avg')
            )}${getSimplifiedUnit(getUnit(aggregates.jumping_pronation_angle_amplitude_contact_phase))}`,
            diffData: `${getFormattedData(
              getValue(aggregates.jumping_pronation_angle_amplitude_contact_phase.global, 'avg_diff_R_L')
            )}${getSimplifiedUnit(getUnit(aggregates.jumping_pronation_angle_amplitude_contact_phase))}`,
            sideDiff: aggregates.jumping_pronation_angle_amplitude_contact_phase.global.side
          },
          {
            leftData: `${getFormattedData(
              getValue(aggregates.jumping_pronation_angular_speed_contact_phase.left_foot, 'avg')
            )}${getSimplifiedUnit(getUnit(aggregates.jumping_pronation_angular_speed_contact_phase))}`,
            rightData: `${getFormattedData(
              getValue(aggregates.jumping_pronation_angular_speed_contact_phase.right_foot, 'avg')
            )}${getSimplifiedUnit(getUnit(aggregates.jumping_pronation_angular_speed_contact_phase))}`,
            diffData: `${getFormattedData(
              getValue(aggregates.jumping_pronation_angular_speed_contact_phase.global, 'avg_diff_R_L')
            )}${getSimplifiedUnit(getUnit(aggregates.jumping_pronation_angular_speed_contact_phase))}`,
            sideDiff: aggregates.jumping_pronation_angular_speed_contact_phase.global.side
          }
        ]
      }
    ];
  };

  const getSideHopGeneralParametersAdvancedData = (aggregates: any) => {
    return [
      {
        title: 'flexion-angle-at-take-off',
        data: [
          {
            leftData: `${getFormattedData(
              getValue(aggregates.jumping_plantar_flexion_angle_foot_out.left_foot, 'avg')
            )}${getSimplifiedUnit(getUnit(aggregates.jumping_plantar_flexion_angle_foot_out))}`,
            rightData: `${getFormattedData(
              getValue(aggregates.jumping_plantar_flexion_angle_foot_out.right_foot, 'avg')
            )}${getSimplifiedUnit(getUnit(aggregates.jumping_plantar_flexion_angle_foot_out))}`,
            diffData: `${getFormattedData(
              getValue(aggregates.jumping_plantar_flexion_angle_foot_out.global, 'avg_diff_R_L')
            )}${getSimplifiedUnit(getUnit(aggregates.jumping_plantar_flexion_angle_foot_out))}`,
            sideDiff: aggregates.jumping_plantar_flexion_angle_foot_out.global.side
          }
        ]
      },
      {
        title: 'ankle-velocity-at-take-off',
        data: [
          {
            leftData: `${getFormattedData(
              getValue(aggregates.jumping_plantar_flexion_angular_speed_foot_out.left_foot, 'avg')
            )}${getSimplifiedUnit(getUnit(aggregates.jumping_plantar_flexion_angular_speed_foot_out))}`,
            rightData: `${getFormattedData(
              getValue(aggregates.jumping_plantar_flexion_angular_speed_foot_out.right_foot, 'avg')
            )}${getSimplifiedUnit(getUnit(aggregates.jumping_plantar_flexion_angular_speed_foot_out))}`,
            diffData: `${getFormattedData(
              getValue(aggregates.jumping_plantar_flexion_angular_speed_foot_out.global, 'avg_diff_R_L')
            )}${getSimplifiedUnit(getUnit(aggregates.jumping_plantar_flexion_angular_speed_foot_out))}`,
            sideDiff: aggregates.jumping_plantar_flexion_angular_speed_foot_out.global.side
          }
        ]
      },
      {
        title: 'jump-height-foot',
        data: [
          {
            leftData: `${getFormattedData(aggregates.jumping_foot_height.left_foot.avg)}${getSimplifiedUnit(
              aggregates.jumping_foot_height.unit
            )}`,
            rightData: `${getFormattedData(aggregates.jumping_foot_height.right_foot.avg)}${getSimplifiedUnit(
              aggregates.jumping_foot_height.unit
            )}`,
            diffData: `${getFormattedData(
              getValue(aggregates.jumping_foot_height.global, 'avg_diff_R_L')
            )}${getSimplifiedUnit(getUnit(aggregates.jumping_foot_height))}`,
            sideDiff: aggregates.jumping_foot_height.global.side
          }
        ]
      },
      {
        title: 'rotation-around-z',
        data: [
          {
            leftData: `${getFormattedData(
              getValue(aggregates.jumping_relative_heading_angle.left_foot, 'avg')
            )}${getSimplifiedUnit(getUnit(aggregates.jumping_relative_heading_angle))}`,
            rightData: `${getFormattedData(
              getValue(aggregates.jumping_relative_heading_angle.right_foot, 'avg')
            )}${getSimplifiedUnit(getUnit(aggregates.jumping_relative_heading_angle))}`,
            diffData: `${getFormattedData(
              getValue(aggregates.jumping_relative_heading_angle.global, 'avg_diff_R_L')
            )}${getSimplifiedUnit(getUnit(aggregates.jumping_relative_heading_angle))}`,
            sideDiff: aggregates.jumping_relative_heading_angle.global.side
          }
        ]
      },
      {
        title: 'max-acceleration-at-landing',
        data: [
          {
            leftData: `${getFormattedData(
              getValue(aggregates.jumping_acceleration_foot_in.left_foot, 'avg')
            )}${getSimplifiedUnit(getUnit(aggregates.jumping_acceleration_foot_in))}`,
            rightData: `${getFormattedData(
              getValue(aggregates.jumping_acceleration_foot_in.right_foot, 'avg')
            )}${getSimplifiedUnit(getUnit(aggregates.jumping_acceleration_foot_in))}`,
            diffData: `${getFormattedData(
              getValue(aggregates.jumping_acceleration_foot_in.global, 'avg_diff_R_L')
            )}${getSimplifiedUnit(getUnit(aggregates.jumping_acceleration_foot_in))}`,
            sideDiff: aggregates.jumping_acceleration_foot_in.global.side
          }
        ]
      },
      {
        title: 'flexion-angle-at-landing',
        data: [
          {
            leftData: `${getFormattedData(
              getValue(aggregates.jumping_plantar_flexion_angle_foot_in.left_foot, 'avg')
            )}${getSimplifiedUnit(getUnit(aggregates.jumping_plantar_flexion_angle_foot_in))}`,
            rightData: `${getFormattedData(
              getValue(aggregates.jumping_plantar_flexion_angle_foot_in.right_foot, 'avg')
            )}${getSimplifiedUnit(getUnit(aggregates.jumping_plantar_flexion_angle_foot_in))}`,
            diffData: `${getFormattedData(
              getValue(aggregates.jumping_plantar_flexion_angle_foot_in.global, 'avg_diff_R_L')
            )}${getSimplifiedUnit(getUnit(aggregates.jumping_plantar_flexion_angle_foot_in))}`,
            sideDiff: aggregates.jumping_plantar_flexion_angle_foot_in.global.side
          }
        ]
      },
      {
        title: 'pronosupination-angle-landing',
        data: [
          {
            leftData: `${getFormattedData(
              getValue(aggregates.jumping_pronation_angle_foot_in.left_foot, 'avg')
            )}${getSimplifiedUnit(getUnit(aggregates.jumping_pronation_angle_foot_in))}`,
            rightData: `${getFormattedData(
              getValue(aggregates.jumping_pronation_angle_foot_in.right_foot, 'avg')
            )}${getSimplifiedUnit(getUnit(aggregates.jumping_pronation_angle_foot_in))}`,
            diffData: `${getFormattedData(
              getValue(aggregates.jumping_pronation_angle_foot_in.global, 'avg_diff_R_L')
            )}${getSimplifiedUnit(getUnit(aggregates.jumping_pronation_angle_foot_in))}`,
            sideDiff: aggregates.jumping_pronation_angle_foot_in.global.side
          }
        ]
      },
      {
        title: 'contact-stability',
        data: [
          {
            leftData: `${getFormattedData(
              getValue(aggregates.jumping_pronation_angle_amplitude_contact_phase.left_foot, 'avg')
            )}${getSimplifiedUnit(getUnit(aggregates.jumping_pronation_angle_amplitude_contact_phase))}`,
            rightData: `${getFormattedData(
              getValue(aggregates.jumping_pronation_angle_amplitude_contact_phase.right_foot, 'avg')
            )}${getSimplifiedUnit(getUnit(aggregates.jumping_pronation_angle_amplitude_contact_phase))}`,
            diffData: `${getFormattedData(
              getValue(aggregates.jumping_pronation_angle_amplitude_contact_phase.global, 'avg_diff_R_L')
            )}${getSimplifiedUnit(getUnit(aggregates.jumping_pronation_angle_amplitude_contact_phase))}`,
            sideDiff: aggregates.jumping_pronation_angle_amplitude_contact_phase.global.side
          },
          {
            leftData: `${getFormattedData(
              getValue(aggregates.jumping_pronation_angular_speed_contact_phase.left_foot, 'avg')
            )}${getSimplifiedUnit(getUnit(aggregates.jumping_pronation_angular_speed_contact_phase))}`,
            rightData: `${getFormattedData(
              getValue(aggregates.jumping_pronation_angular_speed_contact_phase.right_foot, 'avg')
            )}${getSimplifiedUnit(getUnit(aggregates.jumping_pronation_angular_speed_contact_phase))}`,
            diffData: `${getFormattedData(
              getValue(aggregates.jumping_pronation_angular_speed_contact_phase.global, 'avg_diff_R_L')
            )}${getSimplifiedUnit(getUnit(aggregates.jumping_pronation_angular_speed_contact_phase))}`,
            sideDiff: aggregates.jumping_pronation_angular_speed_contact_phase.global.side
          }
        ]
      }
    ];
  };

  const getSideHopCadenceEvolutionChartOptions = (segments: any) => {
    const { theme, lineType, markerType } = useChart();

    const chartLeftData = segments.jumping_stride_cadence.left_foot.avg;
    const chartRightData = segments.jumping_stride_cadence.right_foot.avg;

    const series = computed(() => {
      return [
        {
          id: 'cadence',
          name: i18n.t('commons.standards.left-feet'),
          data: chartLeftData,
          color: theme.colors.leftFoot,
          type: lineType,
          marker: {
            symbol: markerType
          },
          zIndex: 1
        },
        {
          id: 'cadence',
          name: i18n.t('commons.standards.right-feet'),
          data: chartRightData,
          color: theme.colors.rightFoot,
          type: lineType,
          marker: {
            symbol: markerType
          },
          zIndex: 1
        }
      ];
    });

    return computed(() => {
      return {
        chart: {
          animation: true,
          height: 200
        },
        title: {
          text: 'commons.standards.cadence',
          style: {
            fontSize: '0'
          }
        },
        xAxis: {
          labels: {
            formatter() {
              return '';
            }
          }
        },
        yAxis: {
          opposite: true,
          labels: {
            align: 'left',
            format: `{value}`
          },
          gridLineDashStyle: 'longdash',
          title: {
            text: '',
            margin: 20
          },
          maxZoom: 100
        },
        plotOptions: {
          areaspline: {
            lineWidth: 3,
            fillColor: {
              linearGradient: {
                x1: 0,
                y1: 0,
                x2: 0,
                y2: 1
              },
              stops: [
                [0, 'rgba(186,204,215,0.4)'],
                [1, 'rgba(255,255,255,0.1)']
              ]
            }
          },
          series: {
            enableMouseTracking: true,
            animation: true
          }
        },
        legend: {
          enabled: false
        },
        tooltip: {
          distance: 50,
          crosshairs: true,
          followPointer: true
        },
        series: series.value
      };
    });
  };

  const getActiveWidgets = (mode: ERehabMode) => {
    switch (mode) {
      case 'triple-hop':
        return ['total-distance-symmetry', 'total-distance'];
      case 'CMJ':
        return [];
      case 'single-hop':
        return ['total-distance-symmetry', 'total-distance'];
      case 'side-hop':
        return ['symmetry', 'jump-count'];
      default:
        return [];
    }
  };

  // Image helper functions

  const getPlantarFlexionAngleType = (data: any) => {
    const average: number = data.left_foot.avg + data.right_foot.avg / 2;
    if (average < -5) {
      return 'plantar-angle-flexion-heelstrike';
    } else if (average >= -5 && average <= 5) {
      return 'plantar-angle-flexion-flatfoot';
    } else {
      return 'plantar-angle-flexion-forefoot';
    }
  };

  return {
    getSimplifiedUnit,
    getFormattedData,
    getMode,
    getActiveWidgets,
    getTripleHopJumpProfileData,
    getTripleHopGeneralParametersSimplifiedData,
    getTripleHopGeneralParametersAdvancedData,
    getCMJGeneralParametersSimplifiedData,
    getCMJGeneralParametersAdvancedData,
    getSingleHopGeneralParametersAdvancedData,
    getSingleHopGeneralParametersSimplifiedData,
    getSideHopGeneralParametersSimplifiedData,
    getSideHopGeneralParametersAdvancedData,
    getSideHopCadenceEvolutionChartOptions
  };
}
