import { useUnit } from '@/utils/unit.utils';
import { ESide } from '@/plugins/charts/gaitline/gaitline';
import { rounded } from '@/helpers/conversion.helper';
import { chartColors } from '@/utils/charts-colors';

export enum EPhaseValuesTypesUnit {
  Absolute = 'abs',
  Relative = 'rel'
}

import { staticValues } from './static-values.helper';

export enum EValueTypeUnit {
  Speed = 'speed',
  Distance = 'distance',
  TotalDistance = 'totaldistance',
  CenterOfMass = 'centerofmass',
  JumpHeight = 'jumpheight',
  Circumduction = 'circumduction',
  Clearance = 'clearance',
  StrideLength = 'stridelength'
}

// Gaitline
export function getSymmetryValue(value: number, side: ESide) {
  const symPoint = 65.85; // center point of symmetry
  if (side === 'right') {
    return symPoint + (symPoint - value);
  }

  return value;
}

export function getPrctValue(value: any, side: ESide, average: any) {
  if (side === 'left') {
    return ((value - average.min) * 100) / (average.max - average.min) / 100;
  }

  return (-1 * ((value - average.min) * 100)) / (average.max - average.min) / 100;
}

// Angles prosup

export function getPercentPos(value: number, reverse: boolean, containerScale: any) {
  let result = ((value - containerScale.min) * 100) / (containerScale.max - containerScale.min);

  result = reverse ? 100 - result : result;
  return result / 100;
}

export function getBlockMiddle(firstValue: number, lastValue: number, padding: number) {
  return padding + firstValue + (lastValue - firstValue) / 2;
}

// Angle prosup running tooltip

export function getAngleRange(angle: number, type: string, mode: string, corridor: any) {
  const range = corridor[type];

  if (mode === 'rel') return chartColors.neutral.fill;

  if (angle < range.min || angle > range.max) return chartColors.neutral.fill;

  return chartColors.good.fill;
}

export function getStdValueRange(angle: number, type: string, mode: string, corridor: any) {
  const range = corridor[type];
  const formatAngle = rounded(angle, 1);

  if (mode === 'rel')
    return {
      color: '#717788',
      text: `${formatAngle}°`
    };

  if (angle < range.min)
    return {
      color: chartColors.warning.fill,
      text: `⇩ ${formatAngle}°`
    };

  if (angle > range.max)
    return {
      color: chartColors.warning.fill,
      text: `⇧ ${formatAngle}°`
    };

  return {
    color: chartColors.good.fill,
    text: `${formatAngle}°`
  };
}

export function getYTooltipPosition(value: any, height: number, container: any, globalHeight: number) {
  const padding = 30;
  if (value - height / 2 < container.chart.paddingTop) return container.chart.paddingTop - padding;
  if (value + height / 2 > globalHeight - container.chart.paddingBottom)
    return globalHeight - container.chart.paddingBottom - height + padding;
  return value - height / 2;
}

// Angles prosup walking feet

export function isNegativ(angle: number) {
  return angle < 0;
}

// Valid values

export function areValidValues(valuesList: number[] | undefined[] | null[]) {
  for (const value of valuesList) if (value === undefined || value === null || isNaN(value)) return false;
  return true;
}

// Units

export function getUnit(valueType: EValueTypeUnit) {
  const { unitByValueType } = staticValues();
  const { currentUnit } = useUnit();

  return unitByValueType[valueType][currentUnit().current.value];
}
