



























































// Import vendors ----------------------------------------------------------------------------------
import { computed, defineComponent, nextTick, onMounted, ref, watch } from '@vue/composition-api';
// Import plugins ----------------------------------------------------------------------------------
import { usePodocoreModule } from '@/plugins/podocore';
// Import configurations ---------------------------------------------------------------------------
import { apiConfig } from '@/config/api.config';
// Import components -------------------------------------------------------------------------------
import CompositeDialog from '@/components/composite/CompositeDialog.vue';
import AlertError from '@/components/alerts/AlertError.vue';
import AlertSuccess from '@/components/alerts/AlertSuccess.vue';
// Import types ------------------------------------------------------------------------------------
import type { DialogId } from '@/plugins/podocore/modules/bus/bus.module';
// Import Utils -----------------------------------------------------------------------------------
import { useAnalytics } from '@/utils/analytics.utils';
import { useI18n } from '@/utils/i18n.utils';
// -------------------------------------------------------------------------------------------------

export default defineComponent({
  name: 'DialogPatientDelete',
  components: { CompositeDialog, AlertError, AlertSuccess },
  setup() {
    const dialogId: DialogId = 'patient-delete';
    const rDialog = ref<any>(null);

    const { trackSuccess, trackFailure } = useAnalytics();
    const { dateFormat } = useI18n();

    const requestModule = usePodocoreModule('request');
    const busModule = usePodocoreModule('bus');

    let patientRequest = ref<any>(null);
    let patientDeleteRequest = ref<any>(null);

    onMounted(() => {
      watch(
        () => rDialog.value.displayContent,
        (value: boolean) => {
          if (value) {
            patientRequest.value = requestModule.useAuthenticatedRequest(
              `${apiConfig.default}/patients/${rDialog.value.meta.patientCuid}`
            );
            patientDeleteRequest.value = requestModule.useAuthenticatedRequest(
              `${apiConfig.default}/patients/${rDialog.value.meta.patientCuid}`,
              {
                axios: {
                  method: 'DELETE'
                }
              }
            );

            patientRequest.value.request();
          } else {
            patientRequest.value.cancel();
            patientDeleteRequest.value.cancel();

            nextTick(() => {
              patientRequest.value.clear();
              patientDeleteRequest.value.clear();

              patientRequest.value = null;
            });
          }
        }
      );
    });

    function deletePatient() {
      patientDeleteRequest.value.request();
    }

    function closeDialog() {
      rDialog.value.close();
    }

    const isMounted = computed(() => {
      return patientRequest.value && patientDeleteRequest.value;
    });

    const isDisabled = computed(() => {
      return !patientRequest.value || patientRequest.value.isPending;
    });

    const isSuccess = computed(() => {
      return !patientDeleteRequest.value?.error && !!patientDeleteRequest.value?.data;
    });

    const isError = computed(() => {
      return patientDeleteRequest.value?.error;
    });

    watch(isSuccess, (value) => {
      if (value) {
        busModule.publish(
          busModule.events.patientDeleted({
            patientCuid: rDialog.value.meta.patientCuid
          })
        );
        trackSuccess('Delete Patient');
      }
    });
    watch(isError, (value) => {
      if (value) trackFailure('Delete Patient', 'Patient deletion failed');
    });

    return {
      rDialog,
      dialogId,
      patientRequest,
      patientDeleteRequest,
      // Methods
      deletePatient,
      closeDialog,
      // Flags
      isMounted,
      isDisabled,
      isSuccess,
      // Utils
      trackSuccess,
      dateFormat
    };
  }
});
