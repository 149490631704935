


































































// Import vendors ----------------------------------------------------------------------------------
import {
  computed,
  defineComponent,
  toRefs,
  reactive,
  watch,
  onMounted,
  nextTick
} from '@vue/composition-api';
// Import types ------------------------------------------------------------------------------------
import type { PropType } from '@vue/composition-api';
import type { FormFieldText } from '@/utils/forms.utils';
// Import helpers ----------------------------------------------------------------------------------
import { getInchFromFeet, getFeetFromFeetAndInch } from '@/helpers/conversion.helper';
// -------------------------------------------------------------------------------------------------

export default defineComponent({
  name: 'FormFieldTextPatientHeight',
  inheritAttrs: false,
  props: {
    field: {
      type: Object as PropType<FormFieldText>,
      required: true
    },
    isImperial: {
      type: Boolean
    }
  },
  setup(properties, { slots }) {
    // Use this property to have typing in DOM
    const { field, isImperial } = toRefs(properties);

    const maxHeight = reactive({
      metric: 270,
      imperial: {
        feet: 8,
        inch: 9
      }
    });
    const minHeight = reactive({
      metric: 45,
      imperial: {
        feet: 1,
        inch: 6
      }
    });

    const imperialInchMinMax = computed(() => {
      return {
        max: imperialHeight.feet === maxHeight.imperial.feet ? maxHeight.imperial.inch : 11,
        min: imperialHeight.feet === minHeight.imperial.feet ? minHeight.imperial.inch : 0
      };
    });

    const hasProgressSlot = computed(() => {
      return Boolean(slots.progress);
    });

    const imperialHeight = reactive({
      feet: 0,
      inch: 0
    });

    watch(imperialHeight, () => {
      if (isImperial.value) {
        field.value.value = getFeetFromFeetAndInch(imperialHeight.feet, imperialHeight.inch);
      }
      if (imperialHeight.inch < imperialInchMinMax.value.min)
        imperialHeight.inch = imperialInchMinMax.value.min;
      if (imperialHeight.inch > imperialInchMinMax.value.max)
        imperialHeight.inch = imperialInchMinMax.value.max;
    });

    onMounted(() => {
      const f = field.value;
      nextTick(() => {
        if (f.value && isImperial.value) {
          imperialHeight.feet = Math.floor(f.value);
          imperialHeight.inch = getInchFromFeet(f.value);
        }
      });
    });

    return {
      // Standards
      maxHeight,
      minHeight,
      imperialInchMinMax,
      // Values
      imperialHeight,
      f: field as any as FormFieldText,
      // Flags
      hasProgressSlot
    };
  }
});
