






















































// Import vendors ----------------------------------------------------------------------------------
import { computed, defineComponent, nextTick, onMounted, ref, watch } from '@vue/composition-api';
// Import plugins ----------------------------------------------------------------------------------
import { usePodocoreModule } from '@/plugins/podocore';
// Import utils ------------------------------------------------------------------------------------
import { useUnit } from '@/utils/unit.utils';
import { useAnalytics } from '@/utils/analytics.utils';
import { usePatient } from '@/utils/patient.utils';
// Import configurations ---------------------------------------------------------------------------
import { apiConfig } from '@/config/api.config';
// Import components -------------------------------------------------------------------------------
import CompositeDialog from '@/components/composite/CompositeDialog.vue';
import AlertError from '@/components/alerts/AlertError.vue';
import AlertSuccess from '@/components/alerts/AlertSuccess.vue';
import PatientForm from '@/components/patient/PatientForm.vue';
// Import types ------------------------------------------------------------------------------------
import type { DialogId } from '@/plugins/podocore/modules/bus/bus.module';
// -------------------------------------------------------------------------------------------------

export default defineComponent({
  name: 'DialogPatientUpdate',
  components: {
    CompositeDialog,
    AlertError,
    AlertSuccess,
    PatientForm
  },
  props: {
    useCache: {
      type: Boolean,
      default: true
    }
  },
  setup(properties) {
    const dialogId: DialogId = 'patient-update';
    const rDialog = ref<any>(null);

    const { trackSuccess, trackFailure } = useAnalytics();

    const requestModule = usePodocoreModule('request');
    const busModule = usePodocoreModule('bus');

    const currentUnit = useUnit().currentUnit();

    let patientRequest = ref<any>(null);
    let patientPatchRequest = ref<any>(null);

    const isMounted = computed(() => {
      return patientRequest.value && patientPatchRequest.value;
    });

    const isDisabled = computed(() => {
      return !patientRequest.value || patientRequest.value.isPending;
    });

    const isSuccess = computed(() => {
      return (
        !patientPatchRequest.value?.isPending &&
        !patientPatchRequest.value?.error &&
        !!patientPatchRequest.value?.data
      );
    });

    const isError = computed(() => {
      return patientPatchRequest.value?.error;
    });

    if (properties.useCache) {
      const { setPatient } = usePatient();

      watch(isSuccess, (value) => {
        if (value) setPatient(ref(patientPatchRequest.value.data));
      });
    }

    onMounted(() => {
      watch(
        () => rDialog.value.displayContent,
        (value: boolean) => {
          if (value) {
            patientRequest.value = requestModule.useAuthenticatedRequest(
              `${apiConfig.default}/patients/${rDialog.value.meta.patientCuid}`
            );
            patientPatchRequest.value = requestModule.useAuthenticatedRequest(
              `${apiConfig.default}/patients/${rDialog.value.meta.patientCuid}/infos?unit=${currentUnit.current.value}`,
              {
                axios: {
                  method: 'PATCH'
                }
              }
            );

            patientRequest.value.request();
          } else {
            patientRequest.value.cancel();
            patientPatchRequest.value.cancel();

            nextTick(() => {
              patientRequest.value.clear();
              patientPatchRequest.value.clear();

              patientRequest.value = null;
            });
          }
        }
      );
    });

    function patchPatient(infos: any) {
      patientPatchRequest.value.request({
        axios: {
          method: 'PATCH',
          data: infos
        }
      });
    }

    function closeDialog() {
      rDialog.value.close();
    }

    function submitForm() {
      rDialog.value.$children[0].$children[0].$children[0].$children[3].submit();
    }

    watch(isSuccess, (value) => {
      if (value) {
        busModule.publish(
          busModule.events.patientPatched({
            patient: patientPatchRequest.value.data
          })
        );
        trackSuccess('Edit Patient', {
          country: patientPatchRequest.value.data.infos.address.country,
          city: patientPatchRequest.value.data.infos.address.city
        });
      }
    });
    watch(isError, (value) => {
      if (value) trackFailure('Edit Patient', 'Patient edition failed');
    });

    return {
      rDialog,
      dialogId,
      patientRequest,
      patientPatchRequest,
      // Methods
      submitForm,
      patchPatient,
      closeDialog,
      // Flags
      isMounted,
      isDisabled,
      isSuccess
    };
  }
});
