// Import vendors ----------------------------------------------------------------------------------
import { Ref, inject, ref } from '@vue/composition-api';
// Import type -------------------------------------------------------------------------------------
import { Entity } from '@/plugins/podocore/helpers/repositories.helper';
import { Patient, PatientInfos } from '@/plugins/podocore/repositories/patients.repository';
// -------------------------------------------------------------------------------------------------

type TReferencePatient = Ref<Entity<Patient>>;
type TResponse = {
  data: Ref<Entity<Patient> | undefined>;
  setPatient: (patient: TReferencePatient) => void;
  setPatientInfos: (infos: PatientInfos) => void;
};

export function usePatient(): TResponse {
  const data: Ref<Entity<Patient> | undefined> = inject('patient') ?? ref(undefined);

  const setPatient = (patient: TReferencePatient) => {
    data.value = patient.value;
  };

  const setPatientInfos = (infos: PatientInfos) => {
    (data.value as Entity<Patient>).infos = infos;
  };

  return {
    data,
    setPatient,
    setPatientInfos
  };
}
