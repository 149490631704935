








import { computed, defineComponent } from '@vue/composition-api';

export default defineComponent({
  name: 'Page',
  setup(_, { root }) {
    const isWideScreen = computed(() => {
      return root.$vuetify.breakpoint.mdAndUp;
    });

    return {
      isWideScreen
    };
  }
});
