export const chartColors: any = {
  good: {
    fill: '#18922a',
    background: '#ebfaea'
  },
  warning: {
    fill: '#e28f00',
    background: '#fff7e6'
  },
  bad: {
    fill: '#aa0f15',
    background: '#fdebe9'
  },
  neutral: {
    fill: '#020c27',
    background: '#fafafa'
  }
};

export const sideColors = {
  left: {
    fill: '#ffbd1a',
    background: '#EFE9FF'
  },
  right: {
    fill: '#2962fd',
    background: '#EBF4FD'
  }
};
