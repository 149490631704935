var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"form__field__phone"},[_c('div',{staticClass:"form__field__phone__dial"},[_c('ValidationProvider',{ref:"rValidationProvider",attrs:{"rules":_vm.fc.rules,"name":_vm.fc.key,"slim":""}},[_c('VAutocomplete',_vm._b({ref:"rPhoneCountryCode",staticClass:"ma-0 pa-0",attrs:{"items":_vm.countries,"item-text":"dial","item-value":"dial","prefix":"+","hide-details":"","single-line":"","outlined":"","filter":_vm.dialFilter,"autocomplete":"tel-country-code","aria-label":_vm.$t('commons.standards.area-code')},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
var on = ref.on;
return [_c('VListItem',_vm._g({attrs:{"dense":""}},on),[_c('VListItemContent',[_c('VListItemTitle',[_vm._v("+"+_vm._s(item.dial))]),_c('VListItemActionText',{staticClass:"ml-2"},[_c('VList',_vm._l((item.countries),function(country){return _c('VListItem',{key:country.alpha2,attrs:{"dense":""}},[_c('CountryFlag',{attrs:{"country-code":country.alpha2.toLowerCase()}}),_c('span',{staticClass:"ml-2"},[_vm._v(_vm._s(country.name))])],1)}),1)],1)],1)],1)]}}]),model:{value:(_vm.fc.value),callback:function ($$v) {_vm.$set(_vm.fc, "value", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"fc.value"}},'VAutocomplete',Object.assign({}, _vm.fc.attributes, _vm.omit(_vm.$attrs, 'label')),false))],1)],1),_c('div',{staticClass:"form__field__phone__value pl-2"},[_c('ValidationProvider',{ref:"rValidationProvider",attrs:{"rules":_vm.fn.rules,"name":_vm.fn.key,"slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
var changed = ref.changed;
return [_c('VTextField',_vm._g(_vm._b({staticClass:"form__field__phone",attrs:{"type":"tel","success":valid && changed,"error-messages":errors,"hide-details":!errors || !errors.length,"autocomplete":"tel-national"},model:{value:(_vm.fn.value),callback:function ($$v) {_vm.$set(_vm.fn, "value", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"fn.value"}},'VTextField',Object.assign({}, _vm.fn.attributes, _vm.$attrs),false),_vm.$listeners))]}}])})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }