
























































































































// Import vendors ----------------------------------------------------------------------------------
import { defineComponent, ref, toRefs, watch } from '@vue/composition-api';
import { ValidationObserver } from 'vee-validate';
import { omit } from 'lodash';
// Import utils ------------------------------------------------------------------------------------
import { getGroupedByDialCountries } from '@/utils/i18n.utils';
// Import components -------------------------------------------------------------------------------
import CountryFlag from '@/components/CountryFlag.vue';
// Import types ------------------------------------------------------------------------------------
import type { PropType } from '@vue/composition-api';
import type { FormField } from '@/utils/forms.utils';
// -------------------------------------------------------------------------------------------------

export default defineComponent({
  name: 'FormFieldPhone',
  components: { CountryFlag },
  inheritAttrs: false,
  props: {
    fieldNumber: {
      type: Object as PropType<FormField>,
      required: true
    },
    fieldDial: {
      type: Object as PropType<FormField>,
      required: true
    }
  },
  setup(properties) {
    const rValidationProvider = ref<InstanceType<typeof ValidationObserver>>(null!);
    const rPhoneCountryCode = ref<HTMLInputElement>(null!);
    // Use this property to have typing in DOM
    const { fieldNumber, fieldDial } = toRefs(properties);

    const countries = getGroupedByDialCountries();

    function dialFilter(item: typeof countries[0], queryText: string) {
      const search = queryText.replace(/\+/, '');
      const regex = new RegExp(search, 'giu');

      const hasDialMatch = item.dial
        .split(',')
        .map((dial) => regex.test(dial))
        .some((result) => result);

      const hasCountryMatch = item.countries
        .map((country) => regex.test(country.alpha3) || regex.test(country.name))
        .some((result) => result);

      return hasDialMatch || hasCountryMatch;
    }

    watch(fieldDial.value, () => {
      rPhoneCountryCode.value.blur();

      rValidationProvider.value.validate();
    });

    return {
      // References
      rValidationProvider,
      rPhoneCountryCode,
      // Fields
      fn: fieldNumber as any as FormField,
      fc: fieldDial as any as FormField,
      // Values
      countries,
      // Methods
      omit,
      dialFilter
    };
  }
});
