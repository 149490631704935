import i18n from '@/plugins/i18n';

export enum EUnitKey {
  Millisecond = 'ms',
  KilometerPerHour = 'km/h',
  MilePerHour = 'mph',
  StepPerMinute = 'step/min',
  Meter = 'm',
  Feet = 'ft',
  Centimeter = 'cm',
  Degree = 'deg',
  DegreePerSecond = 'deg/s',
  Percent = 'SU',
  Inch = 'inch',
  In = 'in',
  Newton = 'N',
  KiloNewton = 'kN',
  Jump = 'jump',
  Yard = 'yard',
  Force = 'g'
}

type TUnitKeys = {
  [EUnitKey.Millisecond]: TUnitKey;
  [EUnitKey.KilometerPerHour]: TUnitKey;
  [EUnitKey.MilePerHour]: TUnitKey;
  [EUnitKey.StepPerMinute]: TUnitKey;
  [EUnitKey.Meter]: TUnitKey;
  [EUnitKey.Feet]: TUnitKey;
  [EUnitKey.Centimeter]: TUnitKey;
  [EUnitKey.Degree]: TUnitKey;
  [EUnitKey.DegreePerSecond]: TUnitKey;
  [EUnitKey.Percent]: TUnitKey;
  [EUnitKey.Inch]: TUnitKey;
  [EUnitKey.In]: TUnitKey;
  [EUnitKey.Newton]: TUnitKey;
  [EUnitKey.KiloNewton]: TUnitKey;
  [EUnitKey.Jump]: TUnitKey;
  [EUnitKey.Yard]: TUnitKey;
  [EUnitKey.Force]: TUnitKey;
};

type TUnitKey = {
  sign: string;
  key: string;
};

const unitKeys: TUnitKeys = {
  [EUnitKey.Millisecond]: {
    sign: 'ms',
    key: 'millisecond'
  },
  [EUnitKey.KilometerPerHour]: {
    sign: 'km/h',
    key: 'kilometer-per-hour'
  },
  [EUnitKey.MilePerHour]: {
    sign: 'mph',
    key: 'mile-per-hour'
  },
  [EUnitKey.StepPerMinute]: {
    sign: `${(i18n.t('commons.units.steps') as any).slice(0, 1)}/min`,
    key: 'step-min'
  },
  [EUnitKey.Meter]: {
    sign: 'm',
    key: 'meter'
  },
  [EUnitKey.Feet]: {
    sign: 'ft',
    key: 'feet'
  },
  [EUnitKey.Centimeter]: {
    sign: 'cm',
    key: 'centimeter'
  },
  [EUnitKey.Degree]: {
    sign: '°',
    key: 'degree'
  },
  [EUnitKey.DegreePerSecond]: {
    sign: '°/s',
    key: 'degree-per-second'
  },
  [EUnitKey.Percent]: {
    sign: '%',
    key: 'percent'
  },
  [EUnitKey.Inch]: {
    sign: 'In',
    key: 'inch'
  },
  [EUnitKey.In]: {
    sign: 'in',
    key: 'in'
  },
  [EUnitKey.Newton]: {
    sign: 'N',
    key: 'newton'
  },
  [EUnitKey.KiloNewton]: {
    sign: 'kN',
    key: 'kilo-newton'
  },
  [EUnitKey.Jump]: {
    sign: i18n.tc('commons.standards.jump', 2) as string,
    key: 'jump'
  },
  [EUnitKey.Yard]: {
    sign: 'yard',
    key: 'yard'
  },
  [EUnitKey.Force]: {
    sign: 'g',
    key: 'force'
  }
};

export function useParameterUnits() {
  function getUnitSign(key: EUnitKey) {
    return unitKeys[key].sign;
  }

  function getUnitTranslation(key: EUnitKey) {
    return i18n.tc(`commons.units.${unitKeys[key].key}`, 2);
  }

  return {
    getUnitSign,
    getUnitTranslation
  };
}
