import { computed } from '@vue/composition-api';

import i18n from '@/plugins/i18n';
import { usePodocoreModuleService } from '@/plugins/podocore';
import { EUnit } from '@/plugins/podocore/repositories/doctor.repository';

export type TUnit = {
  key?: string;
  text: string;
  value: EUnit;
  disabled?: boolean;
};

export function useUnit() {
  // In a function because we don't know if the doctor service is loaded
  function currentUnit() {
    const doctorService = usePodocoreModuleService('doctor');

    const current = computed(() => {
      return (doctorService.state.value as any).context.doctor.config.unit as EUnit;
    });

    return {
      current,
      isImperial: computed(() => current.value === EUnit.Imperial)
    };
  }

  const supportedUnits: TUnit[] = [
    {
      key: 'settings.doctor.config.unit.metric',
      text: i18n.t('settings.doctor.config.unit.metric').toString(),
      value: EUnit.Metric
    },
    {
      key: 'settings.doctor.config.unit.imperial',
      text: i18n.t('settings.doctor.config.unit.imperial').toString(),
      value: EUnit.Imperial
    }
  ];

  return {
    supportedUnits,
    currentUnit
  };
}
