var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('CompositeDialog',{ref:"rDialog",staticClass:"dialog__podomigration",attrs:{"id":_vm.dialogId,"persistent":"","fullscreen":"","tile":"","card.color":"#F5F7FC"},scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('div',{staticClass:"d-flex align-center"},[_c('VImg',{staticClass:"auth__wrapper__logo ma-2 mx-auto",attrs:{"src":_vm.cdn('image', 'main-logo-text-black.png'),"width":"240"}}),_c('div',{staticClass:"capitalize secondary--text mt-3 ml-3"},[_vm._v(" "+_vm._s(_vm.$t('podomigration.title').replace('Podosmart ', ''))+" ")])],1)]},proxy:true}])},[_c('DialogPodomigrationLink'),_c('div',{staticClass:"dialog__podomigration mb-6"},[(_vm.fetchPodosmartPatients.error.value)?_c('AlertError',{staticClass:"mt-4",attrs:{"error":_vm.fetchPodosmartPatients.error.value,"hide-title":""}}):(_vm.fetchPodosmartPatients.isPending.value)?_c('div',{staticClass:"d-flex align-center mt-2"},[_c('VProgressCircular',{attrs:{"indeterminate":"","color":"primary","width":"2","size":"16"}}),_c('div',{staticClass:"ml-2"},[_vm._v(_vm._s(_vm.$t('podomigration.pending')))])],1):_vm._e(),_c('VCard',{staticClass:"mt-4",attrs:{"flat":""}},[_c('VCardText',[_c('VTextField',{staticClass:"patients__list__search pa-0",attrs:{"label":_vm.$t('commons.sentences.filter-patient'),"hint":("( " + (_vm.$t('commons.sentences.filter-patient-example')) + " )"),"prepend-inner-icon":"$filter","persistent-hint":"","dense":"","clearable":"","outlined":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('VDataTable',_vm._b({staticClass:"patients__list__table",attrs:{"server-items-length":_vm.sanitizedData.totalDocs,"items":_vm.sanitizedData.docs,"loading":_vm.isDelayedPending,"loading-text":((_vm.$t('commons.standards.loading')) + "..."),"disable-filtering":_vm.isDelayedPending,"disable-pagination":_vm.isDelayedPending,"footer-props":{
          disablePagination: _vm.isDelayedPending,
          disableItemsPerPage: _vm.isDelayedPending,
          itemsPerPageOptions: [5, 10, 20, 50]
        },"expand-icon":"fad fa-check-circle"},on:{"update:options":function (options) { return _vm.updateOptions(options); }},scopedSlots:_vm._u([{key:"top",fn:function(){return [(_vm.fetchPodosmartPatients.error.value)?_c('AlertError',{staticClass:"mb-4",attrs:{"error":_vm.fetchPodosmartPatients.error.value,"tile":"","hide-title":""}}):_vm._e()]},proxy:true},{key:"no-data",fn:function(){return [_c('div',{staticClass:"no-results"},[_c('div',[_vm._v(_vm._s(_vm.$t('commons.sentences.no-patient-found')))])])]},proxy:true},{key:"no-results",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('commons.sentences.no-patient-found'))+" ")]},proxy:true},{key:"item.infos.lastName",fn:function(ref){
        var value = ref.value;
return [_c('div',{staticClass:"patients__list__calendar"},[_c('div',[_c('span',[_vm._v(_vm._s(value))])])])]}},{key:"item.infos.firstName",fn:function(ref){
        var value = ref.value;
return [_c('div',{staticClass:"patients__list__calendar"},[_c('div',[_c('span',[_vm._v(_vm._s(value))])])])]}},{key:"item.infos.birthDate",fn:function(ref){
        var value = ref.value;
return [_c('div',{staticClass:"patients__list__calendar"},[_c('div',[_c('span',[_vm._v(_vm._s(_vm.capitalize(_vm.$moment(value).format('LL'))))])])])]}},{key:"item.podomigration",fn:function(ref){
        var item = ref.item;
return [_c('div',{class:['item-chip-test', _vm.getChipIndicator(item)]},[_vm._v(" "+_vm._s(_vm.$t(_vm.getChipContent(item)))+" ")])]}},{key:"item.__actions",fn:function(ref){
        var item = ref.item;
return [(!(item.podomigration && !!item.podomigration.patientId))?_c('div',{staticClass:"d-flex justify-center"},[_c('VBtn',{staticClass:"primary-button my-2",attrs:{"depressed":"","rounded":"","disabled":_vm.migratePodosmartPatient.isPending.value || _vm.isDelayedPending,"loading":_vm.migratePodosmartPatient.isPending.value && _vm.migrationId === item._id},on:{"click":function($event){return _vm.migrate(item._id)}}},[_vm._v(_vm._s(_vm.$t('podomigration.actions.migrate')))])],1):_c('div',{staticClass:"d-flex justify-center"},[_c('VBtn',{staticClass:"primary-button--success my-2",attrs:{"depressed":"","rounded":"","disabled":_vm.migratePodosmartPatient.isPending.value || _vm.isDelayedPending,"loading":_vm.migratePodosmartPatient.isPending.value && _vm.migrationId === item._id},on:{"click":function($event){return _vm.open(item.podomigration.patientCuid)}}},[_vm._v(_vm._s(_vm.$t('podomigration.actions.open')))])],1)]}}])},'VDataTable',{ headers: _vm.headers, options: _vm.options },false))],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }