

















// Import vendors ----------------------------------------------------------------------------------
import { defineComponent, onMounted } from '@vue/composition-api';
// Import plugins ----------------------------------------------------------------------------------
import { usePodocoreModule } from '@/plugins/podocore';
// Import utils ------------------------------------------------------------------------------------
import { useRoute } from '@/utils/router.utils';
// Import components -------------------------------------------------------------------------------
import Page from '@/components/Page.vue';
import PageRow from '@/components/PageRow.vue';
import PatientsList from '@/components/patient/PatientsList.vue';
import DialogPatientCreate from '@/components/dialogs/DialogPatientCreate.vue';
import DialogPatientDelete from '@/components/dialogs/DialogPatientDelete.vue';
import ExtendedRouterView from '@/components/ExtendedRouterView.vue';
// -------------------------------------------------------------------------------------------------

export default defineComponent({
  name: 'PagePatients',
  components: {
    Page,
    PageRow,
    PatientsList,
    DialogPatientCreate,
    DialogPatientDelete,
    ExtendedRouterView
  },
  setup() {
    const busModule = usePodocoreModule('bus');

    const { name, params } = useRoute();

    onMounted(() => {
      if (params.value.createPatient) {
        busModule.publish(
          busModule.events.openDialog({
            id: 'patient-create'
          })
        );
      }
    });

    return {
      name
    };
  }
});
