// Import vendors ----------------------------------------------------------------------------------
import { injectable } from 'inversify';
import moment from 'moment';
import { merge } from 'lodash';
// Import factories --------------------------------------------------------------------------------
import { RepositoryFactory } from '../factories/Repository.factory';
// Import configurations ---------------------------------------------------------------------------
import { apiConfig } from '@/config/api.config';
// Import types ------------------------------------------------------------------------------------
import type { Address, Contact, Entity, Pagination } from '@/plugins/podocore/helpers/repositories.helper';
import type { Doctor } from '@/plugins/podocore/repositories/doctor.repository';
import type { Observable } from 'rxjs';
import type { AxiosResponse, AxiosRequestConfig } from 'axios';
// -------------------------------------------------------------------------------------------------

export enum PatientGender {
  Male = 1,
  Female = 2
}

export enum PatientActivityLevel {
  High = 'high_activity_level',
  Moderate = 'moderate_activity_level',
  Low = 'low_sedentary_activity_level'
}

export interface PatientPathology {
  pathologyCuid: string;
  sinceDate?: Date;
}

export interface PatientInfos {
  firstName: string;
  lastName: string;
  birthDate: Date;
  height: number;
  weight: number;
  footSize: number;
  gender: PatientGender;
  pathologies: PatientPathology[];
  address?: Address;
  contact?: Partial<Contact>;
  consultationReason?: string;
  background?: string;
  activityLevel?: PatientActivityLevel;
}

export interface Patient {
  doctorCuid: Entity<Doctor>['cuid'];
  infos: PatientInfos;
}

/**
 * Patients repository
 */
@injectable()
export class PatientsRepository extends RepositoryFactory {
  /**
   * Fetch patients
   */
  fetchPatients(config?: AxiosRequestConfig): Observable<AxiosResponse<Pagination<Entity<Patient>>>> {
    return this._requestModule.authenticatedRequest<Pagination<Entity<Patient>>>(
      `${apiConfig.default}/patients`,
      merge({}, config, {
        params: {
          timestamp: moment().unix()
        }
      })
    );
  }

  /*async fetchPatient(id: string): Observable<Entity<Patient>> {
    return Promise.resolve() as any;
  }*/
}
