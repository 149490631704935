



























































































































































































































































































































// Import vendors ----------------------------------------------------------------------------------
import { computed, defineComponent, onBeforeUnmount, onMounted, ref, watch } from '@vue/composition-api';
import { capitalize } from 'lodash';
// Import plugins ----------------------------------------------------------------------------------
import { usePodocoreModule } from '@/plugins/podocore';
import { useI18n } from '@/utils/i18n.utils';
import { useCDN } from '@/utils/cdn.utils';
// Import utils ------------------------------------------------------------------------------------
import { useDataTable } from '@/utils/data-table.utils';
// Import configurations ---------------------------------------------------------------------------
import { apiConfig } from '@/config/api.config';
// Import components -------------------------------------------------------------------------------
import CompositeDialog from '@/components/composite/CompositeDialog.vue';
import AlertError from '@/components/alerts/AlertError.vue';
import DialogPodomigrationLink from '@/components/dialogs/DialogPodomigrationLink.vue';
// Import types ------------------------------------------------------------------------------------
import type { DialogId } from '@/plugins/podocore/modules/bus/bus.module';
import type { DataTableHeader } from 'vuetify';
// -------------------------------------------------------------------------------------------------

export default defineComponent({
  name: 'DialogPodomigration',
  components: {
    CompositeDialog,
    AlertError,
    DialogPodomigrationLink
  },
  setup(_, { root }) {
    const dialogId: DialogId = 'podomigration';
    const rDialog = ref<any>(null);

    const { dateFormat } = useI18n();
    const { cdn } = useCDN();

    const requestModule = usePodocoreModule('request');
    const busModule = usePodocoreModule('bus');

    const fetchPodosmartPatients = requestModule.useAuthenticatedRequest(
      `${apiConfig.default}/podomigration/patients`
    );

    const migratePodosmartPatient = requestModule.useAuthenticatedRequest(
      `${apiConfig.default}/podomigration/patients/migrate`
    );

    const migratePodosmartAnalysis = requestModule.useAuthenticatedRequest(
      `${apiConfig.default}/podomigration/analysis/migrate`
    );

    const { options, search, sanitizedData, updateOptions, isDelayedPending, request } = useDataTable(
      fetchPodosmartPatients,
      {
        itemsPerPage: 10,
        sortBy: ['infos.lastName'],
        sortDesc: [true]
      }
    );

    const headers = computed((): DataTableHeader[] => [
      {
        text: String(root.$t('commons.standards.last-name')),
        value: 'infos.lastName'
        // width: 180
      },
      {
        text: String(root.$t('commons.standards.first-name')),
        value: 'infos.firstName'
        // width: 180
      },
      {
        text: String(root.$t('commons.standards.birth-date')),
        value: 'infos.birthDate'
        // width: 230
      },
      {
        text: String(root.$t('commons.standards.foot-size')),
        value: 'infos.footSize',
        // width: 140,
        sortable: false,
        align: 'center'
      },
      /*  {
        text: String(root.$t('podomigration.status')),
        value: 'podomigration',
        sortable: false
      }, */
      {
        text: String(root.$tc('commons.standards.action', 2)),
        value: '__actions',
        sortable: false,
        align: 'center'
      }
    ]);

    function getChipIndicator(item: any) {
      return item.podomigration && item.podomigration.patientId
        ? 'success'
        : item.podomigration && item.podomigration.suggestion
        ? 'warning'
        : 'error';
    }
    function getChipContent(item: any) {
      return item.podomigration && item.podomigration.patientId
        ? item.podomigration.type === 'link'
          ? 'podomigration.status.linked'
          : 'podomigration.status.migrated'
        : item.podomigration && item.podomigration.suggestion
        ? 'podomigration.status.not-linked-suggestion-available'
        : 'podomigration.status.not-linked-no-suggestion';
    }

    /* onMounted(() => {
      watch(
        () => rDialog.value.displayContent,
        (value: boolean) => {
          if (!value) {
            fetchPodosmartPatients.cancel();

            nextTick(() => {
              fetchPodosmartPatients.clear();
            });
          } else {
            fetchPodosmartPatients.request();
          }
        }
      );
    }); */

    /* function submitForm() {
      rDialog.value.$children[0].$children[0].$children[0].$children[1].submit();
    } */

    /* function createPatient(infos: any) {
      patientCreateRequest.request({
        axios: {
          method: 'POST',
          data: {
            infos
          }
        }
      });
    } */

    function closeDialog() {
      rDialog.value.close();
    }

    const isDisabled = computed(() => {
      return fetchPodosmartPatients.isPending.value;
    });

    const isSuccess = computed(() => {
      return !fetchPodosmartPatients.error.value && !!fetchPodosmartPatients.data.value;
    });

    const isError = computed(() => {
      return fetchPodosmartPatients.error.value;
    });

    function openDialogPodomigrationLink(podomigrationPatient: any) {
      busModule.publish(
        busModule.events.openDialog({
          id: 'podomigration-link',
          meta: {
            podomigrationPatient
          }
        })
      );
    }

    function migrate(podosmartPatientId: string) {
      migrationId.value = podosmartPatientId;
      migratePodosmartPatient.request({
        axios: {
          method: 'POST',
          data: {
            podosmartPatientId
          }
        }
      });
    }

    const migrationId = ref<any>(undefined);

    busModule.subscribe(busModule.events.podomigrationLinked, () => {
      request();
      migrateAnalyses();
    });

    function migrateAnalyses() {
      /* busModule.publish(
        busModule.events.createNotification({
          message: {
            body: {
              custom: `🔎 ${root.$t('podomigration.notification.importation-preparation')}`
            },
            type: 'custom'
          }
        })
      ); */

      migratePodosmartAnalysis.request({
        axios: {
          method: 'POST',
          data: {
            patientCuid: migratePodosmartPatient.data.value.cuid
          }
        }
      });
    }

    onMounted(() => {
      watch(
        () => rDialog.value.displayContent,
        (value: boolean) => {
          if (value && fetchPodosmartPatients.data.value) {
            fetchPodosmartPatients.clear();
            request();
          }
        }
      );
    });

    onBeforeUnmount(() => {
      migratePodosmartPatient.cancel();
    });

    watch(migratePodosmartPatient.isPending, (value) => {
      if (!value && migratePodosmartPatient.data.value && !migratePodosmartPatient.error.value) {
        request();

        migrateAnalyses();
      }

      if (!value) migrationId.value = undefined;
    });

    watch(migratePodosmartPatient.isPending, (value) => {
      if (!value && migratePodosmartPatient.data.value && !migratePodosmartPatient.error.value) {
        busModule.publish(
          busModule.events.createNotification({
            message: {
              body: {
                custom: `⏳ ${root.$t('podomigration.notification.importation-in-progress')}`
              },
              type: 'custom'
            }
          })
        );
      }
    });

    function open(patientCuid: string) {
      root.$router.push({ path: `/patients/${patientCuid}` });
    }

    /* watch(isSuccess, (value) => {
      if (value) {
        busModule.publish(
          busModule.events.patientCreated({
            patientCuid: patientCreateRequest.data.value.cuid
          })
        );
        trackSuccess('PatientSC/New Patient/Save', {
          pathology: patientCreateRequest.data.value.infos.pathologies,
          height: patientCreateRequest.data.value.infos.height,
          weight: patientCreateRequest.data.value.infos.weight,
          'shoe-size': patientCreateRequest.data.value.infos.footSize,
          country: patientCreateRequest.data.value.infos.address.country,
          city: patientCreateRequest.data.value.infos.address.city
        });

        closeDialog();
      }
    }); */
    /* watch(isError, (value) => {
      if (value) trackFailure('Delete Patient', 'Patient creation failed');
    }); */

    return {
      // References
      rDialog,
      // Values
      dialogId,
      dateFormat,
      search,
      sanitizedData,
      headers,
      isDelayedPending,
      fetchPodosmartPatients,
      migratePodosmartPatient,
      options,
      migrationId,
      // Methods
      getChipIndicator,
      getChipContent,
      openDialogPodomigrationLink,
      updateOptions,
      cdn,
      request,
      open,
      //   submitForm,
      closeDialog,
      capitalize,
      migrate,
      // Flags
      isDisabled,
      isSuccess
    };
  }
});
