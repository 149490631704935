



















// Import vendors ----------------------------------------------------------------------------------
import { computed, defineComponent, toRefs } from '@vue/composition-api';
// Import types ------------------------------------------------------------------------------------
import type { PropType } from '@vue/composition-api';
import type { FormFieldSelect } from '@/utils/forms.utils';
// -------------------------------------------------------------------------------------------------

export default defineComponent({
  name: 'FormFieldSelect',
  inheritAttrs: false,
  props: {
    field: {
      type: Object as PropType<FormFieldSelect>,
      required: true
    }
  },
  setup(properties, { slots, attrs }) {
    // Use this property to have typing in DOM
    const { field } = toRefs(properties);

    const hasItem = computed(() => {
      return Boolean(slots.item);
    });

    const hasSelection = computed(() => {
      return Boolean(slots.selection);
    });

    const isDisabled = computed(() => {
      return attrs.disabled ?? properties.field.attributes?.isDisabled ?? false;
    });

    return {
      f: field as any as FormFieldSelect,
      // Flags
      hasItem,
      hasSelection,
      isDisabled
    };
  }
});
