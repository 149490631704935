
























// Import vendors ----------------------------------------------------------------------------------
import { defineComponent } from '@vue/composition-api';
// Import plugins ----------------------------------------------------------------------------------
import { usePodocoreModule } from '@/plugins/podocore';
import { useI18n } from '@/utils/i18n.utils';
// Import configurations ---------------------------------------------------------------------------
import { apiConfig } from '@/config/api.config';
// -------------------------------------------------------------------------------------------------

export default defineComponent({
  name: 'ElementPatientLink',
  props: {
    patient: {
      type: Object,
      required: true
    },
    pending: {
      type: Boolean
    }
  },
  setup() {
    const { currentLang } = useI18n();
    const requestModule = usePodocoreModule('request');

    const retrievePathologiesRequest = requestModule.useAuthenticatedRequest(
      `${apiConfig.default}/pathologies`
    );

    function getPathology(pathologyCuid: string) {
      return retrievePathologiesRequest?.data?.value?.pathologies.find(
        (pathology: any) => pathology.cuid === pathologyCuid
      );
    }

    function getPathologyPodosmart(pathologyReference: string, pathologies: any[]) {
      const p = pathologies.find((pathology: any) => pathology._id === pathologyReference);
      return p.name[currentLang.value] || p.name['en'];
    }

    return {
      getPathology,
      getPathologyPodosmart
    };
  }
});
