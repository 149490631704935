








































































































































































































































































































































// Import vendors ----------------------------------------------------------------------------------
import {
  computed,
  defineComponent,
  nextTick,
  onBeforeUnmount,
  onMounted,
  ref,
  watch
} from '@vue/composition-api';
import { capitalize } from 'lodash';
// Import components -------------------------------------------------------------------------------
import CompositeDialog from '@/components/composite/CompositeDialog.vue';
import AlertError from '@/components/alerts/AlertError.vue';
import ElementPatientLink from '@/components/elements/ElementPatientLink.vue';
// Import plugins ----------------------------------------------------------------------------------
import { usePodocoreModule } from '@/plugins/podocore';
import { useI18n } from '@/utils/i18n.utils';
import { useRehab } from '@/utils/rehab.utils';
// Import utils ------------------------------------------------------------------------------------
import { useDataTable } from '@/utils/data-table.utils';
// Import configurations ---------------------------------------------------------------------------
import { apiConfig } from '@/config/api.config';
// Import types ------------------------------------------------------------------------------------
import type { DialogId } from '@/plugins/podocore/modules/bus/bus.module';
import type { DataTableHeader } from 'vuetify';
import AlertSuccess from '../alerts/AlertSuccess.vue';
// -------------------------------------------------------------------------------------------------

export default defineComponent({
  name: 'DialogPodomigrationLink',
  components: {
    CompositeDialog,
    AlertError,
    AlertSuccess,
    ElementPatientLink
  },
  setup(_, { root }) {
    const dialogId: DialogId = 'podomigration-link';
    const rDialog = ref<any>(null);

    const { dateFormat, currentLang } = useI18n();
    const { getMode } = useRehab();

    const requestModule = usePodocoreModule('request');
    const busModule = usePodocoreModule('bus');

    const acknowledge = ref(false);

    const podomigrationPatientLinkRequest = requestModule.useAuthenticatedRequest(
      `${apiConfig.default}/podomigration/patients`,
      {
        axios: {
          method: 'GET'
        }
      }
    );

    const podomigrationPatient = computed(() => {
      return rDialog.value?.meta?.podomigrationPatient;
    });

    const selectedPatient = ref<any>(undefined);

    const selectedPatientIsTheOneSuggested = computed(() => {
      if (!selectedPatient.value) return false;
      return podomigrationPatient.value.podomigration?.suggestion?.cuid === selectedPatient.value?.cuid;
    });

    const retrievePathologiesRequest = requestModule.useAuthenticatedRequest(
      `${apiConfig.default}/pathologies`
    );

    const fetchPatientsRequest = requestModule.useAuthenticatedRequest(`${apiConfig.default}/patients`);

    const linkPatientRequest = requestModule.useAuthenticatedRequest(
      `${apiConfig.default}/podomigration/patients/link`
    );

    const {
      options,
      search,
      hasBeenLoadedAtLeastOnce,
      sanitizedData,
      updateOptions,
      isDelayedPending,
      request
    } = useDataTable(
      fetchPatientsRequest,
      {
        itemsPerPage: 3,
        sortBy: ['infos.lastName'],
        sortDesc: [true]
      },
      true,
      true,
      {
        excludePodosmartLink: true
      }
    );

    const headers = computed((): DataTableHeader[] => [
      {
        text: String(root.$t('commons.standards.last-name')),
        value: 'infos.lastName'
      },
      {
        text: String(root.$t('commons.standards.first-name')),
        value: 'infos.firstName'
      },
      {
        text: String(root.$t('commons.standards.birth-date')),
        value: 'infos.birthDate'
      },
      {
        text: String(root.$t('commons.standards.foot-size')),
        value: 'infos.footSize'
      },
      {
        text: String(root.$t('commons.standards.latest-acquisition')),
        value: '_latestAnalysis',
        sortable: false
      }
    ]);

    onMounted(() => {
      retrievePathologiesRequest.request();

      watch(
        () => rDialog.value.displayContent,
        (value: boolean) => {
          if (!value) {
            podomigrationPatientLinkRequest.cancel();
            linkPatientRequest.cancel();

            nextTick(() => {
              podomigrationPatientLinkRequest.clear();
              linkPatientRequest.clear();
            });

            selectedPatient.value = undefined;

            search.value = '';

            acknowledge.value = false;
          } else {
            if (podomigrationPatient.value.podomigration?.suggestion) {
              selectedPatient.value = podomigrationPatient.value.podomigration.suggestion;
            }
          }
        }
      );
    });

    onBeforeUnmount(() => {
      retrievePathologiesRequest.cancel();
      fetchPatientsRequest.cancel();
      acknowledge.value = false;
    });

    /* function submitForm() {
      rDialog.value.$children[0].$children[0].$children[0].$children[1].submit();
    } */

    /* function createPatient(infos: any) {
      patientCreateRequest.request({
        axios: {
          method: 'POST',
          data: {
            infos
          }
        }
      });
    } */

    function getPathology(pathologyCuid: string) {
      return retrievePathologiesRequest?.data?.value?.pathologies.find(
        (pathology: any) => pathology.cuid === pathologyCuid
      );
    }

    function getPathologyPodosmart(pathologyReference: string, pathologies: any[]) {
      const p = pathologies.find((pathology: any) => pathology._id === pathologyReference);
      return p.name[currentLang.value] || p.name['en'];
    }

    function closeDialog() {
      rDialog.value.close();
    }

    function selectPatient(patient: any) {
      selectedPatient.value = patient;

      fetchPatientsRequest.clear();
      search.value = '';
    }

    function linkPatient() {
      if (!selectedPatient.value) throw new Error('A patient must be selected');
      linkPatientRequest.request({
        axios: {
          method: 'POST',
          data: {
            patientCuid: selectedPatient.value.cuid,
            podosmartPatientId: podomigrationPatient.value._id
          }
        }
      });
    }

    const isDisabled = computed(() => {
      return podomigrationPatientLinkRequest.isPending.value || linkPatientRequest.isPending.value;
    });

    const isSuccess = computed(() => {
      return !podomigrationPatientLinkRequest.error.value && !!podomigrationPatientLinkRequest.data.value;
    });

    const isLinkSuccess = computed(() => {
      return !linkPatientRequest.error.value && !!linkPatientRequest.data.value;
    });

    const isError = computed(() => {
      return podomigrationPatientLinkRequest.error.value;
    });

    watch(isLinkSuccess, (value) => {
      if (value) {
        busModule.publish(
          busModule.events.podomigrationLinked({
            patientCuid: selectedPatient.value.cuid
          })
        );
      }
    });

    /* watch(isSuccess, (value) => {
      if (value) {
        busModule.publish(
          busModule.events.patientCreated({
            patientCuid: patientCreateRequest.data.value.cuid
          })
        );
        trackSuccess('PatientSC/New Patient/Save', {
          pathology: patientCreateRequest.data.value.infos.pathologies,
          height: patientCreateRequest.data.value.infos.height,
          weight: patientCreateRequest.data.value.infos.weight,
          'shoe-size': patientCreateRequest.data.value.infos.footSize,
          country: patientCreateRequest.data.value.infos.address.country,
          city: patientCreateRequest.data.value.infos.address.city
        });

        closeDialog();
      }
    }); */
    /* watch(isError, (value) => {
      if (value) trackFailure('Delete Patient', 'Patient creation failed');
    }); */

    function checkIfTextHighlighted(item: any, path: string) {
      const h = item._searchHighlights?.find((_h: any) => _h.path === path);
      return !!h;
    }

    return {
      // References
      rDialog,
      // Values
      dialogId,
      podomigrationPatientLinkRequest,
      dateFormat,
      podomigrationPatient,
      selectedPatient,
      selectedPatientIsTheOneSuggested,
      retrievePathologiesRequest,
      getPathology,
      getPathologyPodosmart,
      search,
      sanitizedData,
      isDelayedPending,
      fetchPatientsRequest,
      linkPatientRequest,
      headers,
      acknowledge,
      // Methods
      //   submitForm,
      closeDialog,
      capitalize,
      getMode,
      checkIfTextHighlighted,
      selectPatient,
      linkPatient,
      // Flags
      isDisabled,
      isSuccess,
      isLinkSuccess
    };
  }
});
