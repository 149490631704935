var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationObserver',{ref:"rObserver",attrs:{"slim":""}},[_c('VForm',{staticClass:"patient__pathology__form",on:{"submit":function($event){$event.preventDefault();return _vm.submit()}}},[_c('VRow',{staticClass:"mt-4"},[_c('VCol',{attrs:{"cols":"12"}},[_c('FormFieldAutoComplete',{attrs:{"field":_vm.pathologyType,"label":((_vm.$t('commons.sentences.pathology-type')) + " *"),"disabled":_vm.isDisabled,"item-value":"cuid","dense":""},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(item.name)+" ")])]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(item.name)+" ")])]}}])})],1),_c('VCol',{attrs:{"cols":"12"}},[_c('FormFieldAutoComplete',{attrs:{"field":_vm.pathologyValue,"label":((_vm.$t('commons.sentences.pathology-name')) + " *"),"disabled":_vm.isDisabled || !_vm.pathologyType.value,"item-disabled":function (x) { return _vm.checkIfAlreadySelected(x); },"item-value":"cuid","dense":""},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('span',{class:{ disabled: _vm.checkIfAlreadySelected(item) }},[_vm._v(" "+_vm._s(item.name)+" ")])]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(item.name)+" ")])]}}])})],1),_c('VCol',{attrs:{"cols":"12"}},[_c('FormFieldDate',{attrs:{"field":_vm.sinceDate,"label":_vm.$t('commons.sentences.pathology-start-date'),"disabled":_vm.isDisabled || !_vm.pathologyType.value,"readonly":""}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }