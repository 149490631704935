














































// Import vendors ----------------------------------------------------------------------------------
import { computed, defineComponent, nextTick, onMounted, ref, toRefs, watch } from '@vue/composition-api';
// Import utils ------------------------------------------------------------------------------------
import { useI18n } from '@/utils/i18n.utils';
// Import types ------------------------------------------------------------------------------------
import type { PropType } from '@vue/composition-api';
import type { FormFieldDate } from '@/utils/forms.utils';
// -------------------------------------------------------------------------------------------------

export default defineComponent({
  name: 'FormFieldDate',
  inheritAttrs: false,
  props: {
    field: {
      type: Object as PropType<FormFieldDate>,
      required: true
    },
    solo: {
      type: Boolean,
      required: false
    },
    showCalendar: {
      type: Boolean,
      required: false
    }
  },
  setup(properties, { attrs, root }) {
    // Use this property to have typing in DOM
    const { field } = toRefs(properties);

    const rMenu = ref<any>(null);

    const { currentLang, dateFormat } = useI18n();

    function fieldToDatePicker() {
      const t = field.value;
      const m = root.$moment(t.value, dateFormat.value);

      if (m.isValid()) {
        const d = m.format('YYYY-MM-DD');

        if (d !== t.picker) {
          t.picker = d;
        }
      }
    }

    function datePickerToField() {
      const t = field.value;
      const m = root.$moment(t.picker, 'YYYY-MM-DD');
      if (m.isValid()) {
        const d =
          field.value?.datePicker?.type && field.value?.datePicker?.type === 'month'
            ? m.format('YYYY-MM').substr(0, 7)
            : m.format(dateFormat.value);

        if (d !== t.value) {
          t.value = d;
        }

        t.menu = false;
      }
    }

    const isDisabled = computed(() => {
      return attrs.disabled ?? properties.field.attributes?.isDisabled ?? false;
    });

    watch(
      () => field.value.value,
      (newdate, olddate) => {
        if (newdate > olddate) {
          if (newdate.length === dateFormat.value.indexOf('/')) field.value.value += '/';
          if (newdate.length === dateFormat.value.lastIndexOf('/')) field.value.value += '/';
        }
      },
      { deep: true }
    );

    watch(
      () => field.value.menu,
      (value) => {
        if (value) {
          if (!field.value.value.length) field.value.value = '01/01/2000';
          fieldToDatePicker();
        }
      }
    );

    onMounted(() => {
      if (field.value?.datePicker?.type && field.value?.datePicker?.type === 'month') {
        nextTick(() => {
          if (field.value.value) {
            field.value.picker = root.$moment(field.value.value).format('YYYY-MM');
            datePickerToField();
          }
        });
      }
    });

    return {
      f: field as any as FormFieldDate,
      rMenu,
      currentLang,
      dateFormat,
      // Methods
      fieldToDatePicker,
      datePickerToField,
      // Flags
      isDisabled
    };
  }
});
