




















// Import vendors ----------------------------------------------------------------------------------
import { computed, defineComponent, toRefs } from '@vue/composition-api';
// Import types ------------------------------------------------------------------------------------
import type { PropType } from '@vue/composition-api';
import type { FormFieldButtonToggle } from '@/utils/forms.utils';
// -------------------------------------------------------------------------------------------------

export default defineComponent({
  name: 'FormFieldButtonToggle',
  inheritAttrs: false,
  props: {
    field: {
      type: Object as PropType<FormFieldButtonToggle>,
      required: true
    }
  },
  setup(properties, { attrs }) {
    // Use this property to have typing in DOM
    const { field } = toRefs(properties);

    const isDisabled = computed(() => {
      return attrs.disabled ?? properties.field.attributes?.isDisabled ?? false;
    });

    return {
      f: (field as any) as FormFieldButtonToggle,
      // Flags
      isDisabled
    };
  }
});
