






















































// Import vendors ----------------------------------------------------------------------------------
import { computed, defineComponent, onMounted, toRefs, watch } from '@vue/composition-api';
import { get } from 'lodash';
// Import utils ------------------------------------------------------------------------------------
import {
  useFormFieldAutoComplete,
  useFormObserver,
  cleanFieldValue,
  useForm,
  useFormFieldDate
} from '@/utils/forms.utils';
import { useI18n } from '@/utils/i18n.utils';
// Import components -------------------------------------------------------------------------------
import FormFieldAutoComplete from '@/components/forms/FormFieldAutoComplete.vue';
import FormFieldDate from '@/components/forms/FormFieldDate.vue';
// -------------------------------------------------------------------------------------------------

export default defineComponent({
  name: 'PatientPathologyForm',
  props: {
    pathologies: {
      type: Object,
      default: null
    },
    editedPathology: {
      type: Object,
      default: null
    },
    patientPathologies: {
      type: Array,
      default: null
    },
    mode: {
      type: String,
      default: 'add'
    },
    isDisabled: {
      type: Boolean,
      default: false
    }
  },
  components: {
    FormFieldAutoComplete,
    FormFieldDate
  },
  setup(properties, { root, emit }) {
    const rObserver = useFormObserver();

    const { pathologies, editedPathology, patientPathologies, mode } = toRefs(properties);

    const { dateFormat } = useI18n();

    const pathologyType = useFormFieldAutoComplete<string>({
      key: 'pathology-type',
      value: null!,
      items: pathologies.value.pathologyTypes,
      rules: {
        required: true
      }
    });

    const pathologyItems = computed(() => {
      return pathologies.value.pathologies.filter((pathology: any) => {
        return pathology.pathologyTypeCuid === pathologyType.value;
      });
    });

    const pathologyValue = useFormFieldAutoComplete<string>({
      key: 'pathology',
      value: null!,
      items: pathologyItems,
      rules: {
        required: true
      }
    });

    const sinceDate = useFormFieldDate({
      key: 'sinceDate',
      value: '',
      rules: {
        required: false
      },
      datePicker: {
        type: 'month',
        scrollable: true,
        'no-title': true,
        dense: true,
        max: root.$moment().subtract(0, 'years').toISOString()
      }
    });

    const { submit } = useForm(
      {
        pathologyType,
        pathologyValue,
        sinceDate
      },
      rObserver,
      () => {
        if (!editedPathology.value || editedPathology.value.cuid !== pathologyValue.value)
          emit('submit', getFields());
      }
    );

    function getFields() {
      const pathology: any = pathologyValue.items.find(
        (pathologyItem: any) => pathologyItem.cuid === pathologyValue.value
      );
      return {
        pathologyPatched: {
          pathology: pathologyItems.value.find(
            (pathologyItem: any) => pathologyItem.cuid === pathologyValue.value
          ),
          sinceDate: cleanFieldValue(sinceDate)
            ? root
                .$moment(root.$moment(`${cleanFieldValue(sinceDate)}-01 12:00:00`).format())
                .format(dateFormat.value)
            : null
        },
        pathologyName: pathology.name
      };
    }

    function checkIfAlreadySelected(item: any) {
      return !!patientPathologies?.value?.find((x: any) => {
        if (mode.value === 'add') return x.pathologyCuid === item.cuid;
        return (
          x.pathologyCuid === item.cuid &&
          pathologyValue.value !== item.cuid &&
          item.cuid !== editedPathology.value.pathology.cuid
        );
      });
    }

    onMounted(() => {
      if (editedPathology.value) {
        pathologyType.value = get(editedPathology.value, 'pathology.pathologyTypeCuid');
        pathologyValue.value = get(editedPathology.value, 'pathology.cuid');
        if (editedPathology.value.sinceDate) {
          sinceDate.value = (root.$moment(get(editedPathology.value, 'sinceDate')) as any).format(
            dateFormat.value
          );
        }
      }

      watch(
        () => pathologyType.value,
        () => {
          pathologyValue.value = null!;
        }
      );
    });

    return {
      // References
      rObserver,
      // Form fields
      pathologyType,
      pathologyValue,
      sinceDate,
      // Methods
      submit,
      checkIfAlreadySelected
    };
  }
});
