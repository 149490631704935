export function getInchFromFeet(feetValue: number): number {
  return rounded((feetValue - Math.floor(feetValue)) * 12);
}

export function getFeetFromFeetAndInch(feet: number, inches: number): number {
  return rounded(feet + inches / 12, 1);
}

export function rounded(value: number, round?: number): number {
  return Number.parseFloat(value.toFixed(round ?? 0));
}
